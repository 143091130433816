import api from "../index";

export const GetEmailTemplatesApi = (params) => api.get(`EmailTemplate/Get-All-Email-Template?${params}`);
export const AddUpdateEmailTemplateApi = (data, header) => api.post('EmailTemplate/Add-Update-Email-Template', data, header);
export const GetEmailTemplateNameApi = () => api.get('EmailTemplate/Get-All-Email-Templates-Name');
export const GetEmailVariablesApi = (emailTemplateId) => api.get(`EmailTemplate/Get-All-Email-Variables/${emailTemplateId}`);
export const GetEmailTemplateByIdApi = (emailTemplateId) => api.get(`EmailTemplate/Get-Email-Template-ById/${emailTemplateId}`);
export const DeleteEmail = (emailId) => api.delete(`EmailTemplate/Delete-Email-Template/${emailId}`);
export const GetEmailVariablesviewApi = (emailTemplateId) => api.get(`EmailTemplate/Get-Email-Template-ById/${emailTemplateId}`);

