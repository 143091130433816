import React, { useEffect, useState } from 'react';
import columns from '../../../columns/ibApproved/accepted';
import TableWithSkeleton from '../../../components/global/table/TableWithSkeleton';
import Pagination from '../../../components/global/pagination/Pagination.jsx';
import ModalComponent from '../../../components/global/modal/ModalComponent.jsx';
import { GetAllibApi, GetAllIBByIdApi } from "../../../api/request/ibapproved.js";
import moment from "moment";
import Eye from '../../../assets/Eye.jsx';
import Edit from '../../../assets/Edit.jsx';
import { Spin } from 'antd';
import { useNavigate } from 'react-router-dom';

export default function IbAccepted({ dates }) {

    const navigate = useNavigate();
    const [pageSize, setPageSize] = useState(10);
    const [page, setPage] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingIBDetails, setIsLoadingIBDetails] = useState(false);
    const [isApprove, setIsPending] = useState(false);
    const [totalRecord, setTotalRecord] = useState(0);
    const [data, setData] = useState([]);
    const [selectedIBId, setSelectedIBId] = useState('');
    const [iBDetails, setIBDetails] = useState({});
    const [sorter, setSorter] = useState({ SortBy: 'actionDate', SortOrder: '' });

    const getAllIB = async () => {
        try {
            let params = {
                PageNumber: page,
                PageSize: pageSize,
                SearchText: dates,
                FilterText: "Approved",
                ...sorter
            }
            setIsLoading(true);
            const { data } = await GetAllibApi(new URLSearchParams(params).toString());
            if (data?.success) {
                setData(
                    data?.data?.map((item) => {
                        return {
                            ...item,
                            name: `${item?.firstName} ${item?.lastName}`,
                            requestDate: (item?.requestDate ? moment(item?.requestDate).format("DD-MM-YYYY, hh:mm A") : null),
                            actionDate: (item?.actionDate ? moment(item?.actionDate).format("DD-MM-YYYY, hh:mm A") : null),
                            action: (
                                <div className='flex items-center gap-3'>
                                    <button type="button" onClick={() => navigate(`/ib_details/${item?.userId}`)}><Eye /></button>
                                    <button type="button" onClick={() => navigate(`/ib_handler/${item?.userId}`)}><Edit /></button>
                                </div>
                            ),
                        };
                    })
                );
                setTotalRecord(data?.totalRecords);
            }
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
        }
    }
    const handleTableChange = (pagination, filters, sorter) => {
        if (sorter?.order) {
            setSorter({ SortBy: sorter?.field, SortOrder: sorter?.order });
        } else {
            setSorter({ SortBy: 'actionDate', SortOrder: '' });
        }
    };

    useEffect(() => {
        getAllIB();
    }, [page, pageSize, dates, sorter]);

    const getIBById = async () => {
        try {
            setIsLoadingIBDetails(true);
            const { data } = await GetAllIBByIdApi(selectedIBId);
            setIBDetails(data?.data);
            setIsLoadingIBDetails(false);
        } catch (error) {
            setIsLoadingIBDetails(false);
        }
    }

    useEffect(() => {
        if (selectedIBId) {
            getIBById();
        }
    }, [selectedIBId]);
    return (
        <>
            {/* Approved table */}
            <div className="border-[2px] border-[var(--theme-light)] rounded-xl pt-4 lg:pt-6 w-full mt-5">
                <div className="px-4 lg:px-6">
                    <p className="text-[#2F2B3D] text-xl font-semibold">IB Approved Details</p>
                </div>
                <div className="my-6">
                    <TableWithSkeleton columns={columns} data={data} loading={isLoading} onChange={handleTableChange} />
                    <Pagination total={totalRecord} pageSize={pageSize} setPageSize={setPageSize} page={page} setPage={setPage} />
                </div>
            </div>

            {/* approved modal */}
            <ModalComponent isOpen={isApprove} setIsOpen={setIsPending} title='IB Details' width={1300} >
                {
                    isLoadingIBDetails ? (
                        <div className="flex justify-center flex-col my-[5%]">
                            <Spin size="large" />
                            <p className="primary text-center mt-2">Loading...</p>
                        </div>
                    ) : (
                        <div className='p-5'>
                            <div className='grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 gap-10 '>
                                <div>
                                    <p className='text-base font-normal '>First Name</p>
                                    <p className='text-xl primary font-semibold  mt-2'>{iBDetails?.firstName}</p>
                                </div>
                                <div>
                                    <p className='text-base font-normal '>Last Name</p>
                                    <p className='text-xl primary font-semibold  mt-2'>{iBDetails?.lastName}</p>
                                </div>
                                <div>
                                    <p className='text-base font-normal '>Mobile No.</p>
                                    <p className='text-xl primary font-semibold  mt-2'>{iBDetails?.phoneNo}</p>
                                </div>
                                <div>
                                    <p className='text-base font-normal '>Email</p>
                                    <p className='text-xl primary font-semibold  mt-2'>{iBDetails?.email}</p>
                                </div>
                                <div>
                                    <p className='text-base font-normal '>Approve Time</p>
                                    <p className='text-xl primary font-semibold  mt-2'>{iBDetails?.actionDate ? moment(iBDetails?.actionDate).format('DD-MM-YYYY, hh:mm A') : '--'}</p>
                                </div>
                                <div>
                                    <p className='text-base font-normal '>Request Time</p>
                                    <p className='text-xl primary font-semibold  mt-2'>{iBDetails?.createdDate ? moment(iBDetails?.createdDate).format('DD-MM-YYYY, hh:mm A') : '--'}</p>
                                </div>
                                <div>
                                    <p className='text-base font-normal '>IB User</p>
                                    <p className='text-xl primary font-semibold  mt-2'>{iBDetails?.isIBUser === true ? "yes" : "No"}</p>
                                </div>
                            </div>
                        </div>
                    )
                }
            </ModalComponent>
        </>
    )
}
