
import React, { useState } from 'react'
import { Input, Form } from "antd";
import ModalComponent from "../../../components/global/modal/ModalComponent";
import toast from "react-hot-toast";
import { ManualDepositOTPApi, ManualDepositApi } from "../../../api/request/client";
import LoadableButton from "../../../components/buttons/LoadableButton.jsx";


function ManualDeposit({ isManualDeposit, setManualDeposit, id, getTraderById }) {
    const [form] = Form.useForm();
    const [initialValues, setInitialValues] = useState()
    const [isManualLoading, setIsManualLoading] = useState(false);
    const [isManualDepositOtp, setManualDepositOtp] = useState(false);
    const [FormData, setFormData] = useState({ otp: "" });
    const [datanew, setData] = useState();

    const onChange = (e) => {
        const newValue = e.target ? e.target.value : e;
        setFormData({ ...FormData, otp: newValue });
    };

    const sharedProps = {
        onChange,
    };

    const handleSubmitotp = async (values) => {
        setIsManualLoading(true);
        let params = {
            ...values,
            userId: id,
        };
        try {
            const { data } = await ManualDepositOTPApi(params);
            if (data?.success) {
                toast.success(data?.message);
                setData(data?.data);
                setManualDepositOtp(true);
                form.resetFields();
            }
        } catch (error) {
            toast.error(error?.response?.data?.error?.message || 'Amount must be greater than 0.');
        } finally {
            setIsManualLoading(false);
        }
    };

    const handleSubmit = async () => {
        const { otp } = FormData;
        if (!otp) {
            toast.error("OTP can not be empty");
            return;
        }
        try {
            setIsManualLoading(true)
            let params = {
                userId: datanew.userId,
                amount: datanew.amount,
                otp: (FormData.otp).toString(),
            }
            const { data } = await ManualDepositApi(params);
            if (data?.success) {
                toast.success(data?.message);
                setManualDeposit(false);
                setManualDepositOtp(false)
                getTraderById()
                form.resetFields();
            }
            setIsManualLoading(false);
        } catch (error) {
            setIsManualLoading(false)
            toast.error(error?.response?.data?.error?.message || "Something went wrong");
        }
    };

    return (
        <div>
            <ModalComponent isOpen={isManualDeposit} setIsOpen={setManualDeposit} title="Manual Deposit" width={600}>
                {isManualDepositOtp === false ? <Form className='w-full' autoComplete="off" form={form} initialValues={initialValues} onFinish={handleSubmitotp}>
                    <div className="input-white">
                        <label className="text-base text-[#2F2B3DCC] font-medium">Amount</label>
                        <Form.Item name="amount" rules={[{ required: true, message: 'Please enter your amount.' }]}>
                            <Input type='number' placeholder="Please enter your amount" className="mt-1 w-full ps-4 py-2" />
                        </Form.Item>
                    </div>

                    <div className='mt-6'>
                        <div className="flex gap-5 justify-between">
                            <button type='button' onClick={() => setManualDeposit(false)} className='bg-primary text-sm text-white font-semibold rounded-lg px-6 py-2 uppercase themeHover duration-500'>Cancel</button>
                            <LoadableButton
                                type='submit' className='bg-primary text-sm text-white font-semibold rounded-lg px-12 py-2 uppercase themeHover duration-500'
                                disabled={isManualLoading}
                                lable="Submit"
                                isLoading={isManualLoading}
                                loadingLable="Send ..."
                            />
                        </div>
                    </div>
                </Form> : <Form autoComplete="off" onFinish={handleSubmit} >
                    <div className="w-full text-sm py-1">
                        <div className="relative">
                            <Form.Item
                                name="otp"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input.OTP
                                    variant="filled"
                                    {...sharedProps}
                                    value={FormData.otp}
                                    style={{ width: '100%' }}
                                />
                            </Form.Item>

                        </div>
                    </div>
                    <div className="mt-8">
                        <LoadableButton type='submit' className='bg-primary text-[#FFFFFF] font-semibold text-lg rounded-lg w-full py-2' lable='Verify' isLoading={isManualLoading} loadingLable='Verifying...' />
                    </div>
                </Form>
                }

            </ModalComponent>
        </div>
    )
}

export default ManualDeposit





