import React, { useEffect, useState } from 'react';
import { GetEmailVariablesviewApi } from '../../api/request/emailTemplate';
import { useParams } from 'react-router-dom';
import { Spin } from 'antd';

function EmailTemplateView() {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [variableDetails, setVariableDetails] = useState([]);

  const getVariableDetails = async () => {
    try {
      setIsLoading(true);
      const { data } = await GetEmailVariablesviewApi(id);
      if (data?.success) {
        setVariableDetails(data?.data);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    getVariableDetails();
  }, []);
  return (
    <div>
      {isLoading ? (
        <div className="flex justify-center flex-col mt-[20%]">
          <Spin size="large" />
          <p className="primary text-center mt-2">Loading...</p>
        </div>
      ) : (
        <div dangerouslySetInnerHTML={{ __html: variableDetails?.body }}></div>)}
    </div>
  )
}

export default EmailTemplateView
