import React, { useState, useEffect, useRef } from "react";
import { Form, Select, Input, Spin } from "antd";
import columns from "../../../columns/salesDashboard/leads.js";
import TableWithSkeleton from "../../../components/global/table/TableWithSkeleton.jsx";
import ModalComponent from "../../../components/global/modal/ModalComponent.jsx";
import LoadableButton from "../../../components/buttons/LoadableButton.jsx";
import Pagination from "../../../components/global/pagination/Pagination.jsx";
import { GetAllLeadsApi, GetAllSourceApi, AddUpdateLeadApi, UpdateLeadStatusApi, ImportLeadsApi } from "../../../api/request/salesDashboard/leads.js";
import { GetAllCountryApi } from "../../../api/request/common.js";
import toast from "react-hot-toast";
import moment from "moment";
import Edit from "../../../assets/Edit.jsx";
import Eye from "../../../assets/Eye.jsx";
import { LoadingOutlined } from '@ant-design/icons';
import ExportHandler from "../../../components/global/export/ExportHandler.jsx";
const { TextArea } = Input;

export default function Lead() {

  const [form] = Form.useForm();
  const fileInputRef = useRef(null);
  const [isUpdate, setIsUpdate] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isAddLeadLoading, setIsAddLeadLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecord, setTotalRecord] = useState(0);
  const [search, setSearch] = useState("");
  const [searchText, setSearchText] = useState("");
  const [countryFilter, setCountryFilter] = useState([]);
  const [sourceFilter, setSourceFilter] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [countries, setCountries] = useState([]);
  const [sources, setSources] = useState([]);
  const [countriesForFilter, setCountriesForFilter] = useState([]);
  const [sourcesForFilter, setSourcesForFilter] = useState([]);
  const [selectedLead, setSelectedLead] = useState(null);
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);
  const [isImportLoading, setIsImportLoading] = useState(false);
  const [data, setData] = useState([]);
  const [initialValues, setInitialValues] = useState({ firstName: "", lastName: "", email: "", phoneNo: "", countryId: null, sourceId: null, comment: "" });
  const [isFormChanged, setIsFormChanged] = useState(false);
  const [statusFilter, setStatusFilter] = useState([{ value: 1, label: 'New' }, { value: 2, label: 'Attended' }, { value: 3, label: 'Progress' }, { value: 4, label: 'Positive' }, { value: 5, label: 'Won' }, { value: 4, label: 'Lost' }]);

  const handleFieldsChange = (_, allFields) => {
    const isChanged = allFields.some(field => field.touched);
    setIsFormChanged(isChanged);
  };

  let aditionColumns = [
    {
      title: 'Source Name',
      dataIndex: 'sourceName',
      filters: sourcesForFilter,
      onFilter: (value, record) => { return record.sourceName },
      filteredValue: sourceFilter ? sourceFilter : null,
      render: (text) => text || '--',
    },
    {
      title: 'Country',
      dataIndex: 'countryName',
      filters: countriesForFilter,
      filterSearch: true,
      onFilter: (value, record) => { return record.countryName },
      filteredValue: countryFilter ? countryFilter : null,
      render: (text) => text || '--',
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      fixed: "right"
    }
  ];

  const getContries = async () => {
    try {
      const { data } = await GetAllCountryApi();
      if (data?.success) {
        setCountries(
          data?.data?.map((item) => ({
            ...item,
            value: item?.id,
            label: item?.countryName,
          }))
        );
        setCountriesForFilter(
          data?.data?.map((item) => ({
            text: item?.countryName,
            value: item?.countryName,
          }))
        );
      }
    } catch (error) { }
  };

  const getAllSource = async () => {
    try {
      const { data } = await GetAllSourceApi();
      if (data?.success) {
        setSourcesForFilter(data?.data?.map((item) => ({ value: item?.name, text: item?.name })));
        setSources(data?.data?.map((item) => ({ value: item?.id, label: item?.name })));
      }
    } catch (error) { }
  }

  useEffect(() => {
    getAllSource();
    getContries();
  }, []);

  const handleFilterChange = (pagination, filters, sorter) => {
    setCountryFilter(filters.countryName ? filters.countryName : []);
    setSourceFilter(filters.sourceName ? filters.sourceName : []);
  };

  const joinStrings = (array) => {
    return array.filter(item => item).join(', ');
  }

  const openEditModal = (lead) => {
    form.setFieldsValue({ firstName: lead?.firstName, lastName: lead?.lastName, email: lead?.email, phoneNo: lead?.phoneNo, countryId: lead?.countryId, sourceId: lead?.sourceId, comment: lead?.comment });
    setSelectedLead(lead);
    setIsUpdate(true);
    setIsFormChanged(false);
    setIsOpenModal(true);
  }

  useEffect(() => {
    if (!isOpenModal) {
      form.resetFields();
    }
  }, [isOpenModal]);

  const getValueByStatus = (status) => {
    let statusObj = statusFilter?.find(item => item?.label == status);
    return statusObj?.value;
  }
  const spinner = <>
    <div className="flex justify-center items-center">
      <Spin
        indicator={
          <LoadingOutlined
            style={{
              fontSize: 24,
              color: "$theme"
            }}
            spin
          />
        }
      />
    </div>
  </>

  const statusHandler = async (status, itemId) => {
    try {
      let params = { LeadId: itemId, Status: status };
      setData(prev => {
        return prev.map(item => {
          if (item.id == itemId) {
            return { ...item, status: spinner };
          } else {
            return { ...item }
          }
        })
      })
      const { data } = await UpdateLeadStatusApi(params);
      if (data?.success) {
        toast.success(data?.message);
        getAllLeads(false);
      }
    } catch (error) {
      getAllLeads(false);
      if (error?.response?.data?.error?.message) {
        toast.error(error?.response?.data?.error?.message);
      }
    }
  };

  const getAllLeads = async (loding = true) => {
    try {
      if (loding) {
        setIsLoading(true);
      }
      let params = {
        PageNumber: page,
        PageSize: pageSize,
        country: countryFilter,
        sourceName: sourceFilter,
        SearchText: searchText.toLowerCase().replace(/\s/g, '')
      };
      const { data } = await GetAllLeadsApi(new URLSearchParams(params).toString());
      if (data?.success) {
        setData(
          data?.data?.map((item) => {
            return {
              ...item,
              name: `${item?.firstName} ${item?.lastName}`,
              createdDate: (item?.createdDate ? moment(item?.createdDate).format("DD-MM-YYYY, hh:mm A") : null),
              status: (<div className="ant-select-selector-white">
                <Select placeholder="Select a status" className="w-full mb-1" options={statusFilter} value={getValueByStatus(item?.status)} onChange={(status) => statusHandler(status, item?.id)} />
              </div>),
              actions: (<div className="flex items-center gap-3">
                <button onClick={() => openEditModal(item)}><Edit /></button>
                <button onClick={() => { setSelectedLead(item); setIsDetailsModalOpen(true) }}><Eye /></button>
              </div>),
            };
          })
        );
        setTotalRecord(data?.totalRecords);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAllLeads();
  }, [page, pageSize, searchText, countryFilter, sourceFilter]);

  useEffect(() => {
    setPage(1);
  }, [searchText, countryFilter, sourceFilter]);

  const exportHandler = async () => {
    try {
      let params = {
        ApplyPagination: false,
        country: joinStrings([...countryFilter]),
        sourceName: joinStrings([...sourceFilter]),
        SearchText: searchText.toLowerCase().replace(/\s/g, '')
      };
      const { data } = await GetAllLeadsApi(new URLSearchParams(params).toString());
      if (data?.success) {
        return { data: data?.data, removableColumns: ['id'], fileName: 'Leads' };
      } else {
        return { data: [], removableColumns: [] };
      }
    } catch (error) {
      return { data: [], removableColumns: [] };
    }
  };

  const importHandler = async () => {
    fileInputRef.current.click();
  }

  const handleFileChange = async (event) => {
    try {
      const file = event.target.files[0];
      console.log(file);
      if (file) {
        setIsImportLoading(true);
        let apiHeader = { headers: { Accept: "application/json", "Content-Type": "multipart/form-data" } };
        const formData = new FormData();
        formData.append('formfile', file);
        const { data } = await ImportLeadsApi(formData, apiHeader);
        if (data?.success) {
          toast.success(data?.message);
          fileInputRef.current.value = "";
          getAllLeads();
        } else {
          toast.error(data?.message);
          if (fileInputRef.current) {
            fileInputRef.current.value = "";
          }
        }
      }
      setIsImportLoading(false);
    } catch (error) {
      if (error?.response?.data?.error?.message) {
        toast.error(error?.response?.data?.error?.message);
      }
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
      setIsImportLoading(false);
    }
  };

  const handleSubmit = async (value) => {
    try {
      setIsAddLeadLoading(true);
      const { data } = await AddUpdateLeadApi(isUpdate ? { id: selectedLead?.id, ...value } : value);
      if (data?.success) {
        toast.success(data?.message);
        form.resetFields();
        getAllLeads();
        setIsAddLeadLoading(false);
        setIsOpenModal(false);
      }
    } catch (error) {
      if (error?.response?.data?.error?.message) {
        toast.error(error?.response?.data?.error?.message);
      }
      setIsAddLeadLoading(false);
    }
  };

  const filterOption = (input, option) => {
    return (option?.label ?? "").toLowerCase().includes(input.toLowerCase());
  };

  return (
    <div>
      <div className="flex justify-end flex-wrap gap-5">
        <div className="flex items-center flex-wrap gap-4">
          <Input
            size="large"
            style={{ width: 220 }}
            placeholder="Search"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            onPressEnter={() => setSearchText(search)}
            prefix={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                class="w-5 h-5"
              >
                <path
                  fill-rule="evenodd"
                  d="M10.5 3.75a6.75 6.75 0 1 0 0 13.5 6.75 6.75 0 0 0 0-13.5ZM2.25 10.5a8.25 8.25 0 1 1 14.59 5.28l4.69 4.69a.75.75 0 1 1-1.06 1.06l-4.69-4.69A8.25 8.25 0 0 1 2.25 10.5Z"
                  clip-rule="evenodd"
                />
              </svg>
            }
            suffix={
              search && (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  onClick={() => {
                    setSearchText("");
                    setSearch("");
                  }}
                  viewBox="0 0 24 24"
                  fill="#bebebe"
                  className="size-5 cursor-pointer duration-300 hover:fill-[#969595]"
                >
                  <path
                    fill-rule="evenodd"
                    d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm-1.72 6.97a.75.75 0 1 0-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 1 0 1.06 1.06L12 13.06l1.72 1.72a.75.75 0 1 0 1.06-1.06L13.06 12l1.72-1.72a.75.75 0 1 0-1.06-1.06L12 10.94l-1.72-1.72Z"
                    clip-rule="evenodd"
                  />
                </svg>
              )
            }
          />
          <div className="flex items-center gap-4">
            <button
              className="flex items-center bg-primary text-sm text-white font-semibold px-4 py-2 rounded-lg themeHover  duration-500"
              onClick={() => {
                setIsFormChanged(true);
                setIsOpenModal(true);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="w-5 h-5 mr-2"
              >
                <path
                  fill-rule="evenodd"
                  d="M12 3.75a.75.75 0 0 1 .75.75v6.75h6.75a.75.75 0 0 1 0 1.5h-6.75v6.75a.75.75 0 0 1-1.5 0v-6.75H4.5a.75.75 0 0 1 0-1.5h6.75V4.5a.75.75 0 0 1 .75-.75Z"
                  clip-rule="evenodd"
                />
              </svg>
              Add Lead
            </button>
            <ExportHandler exportHandler={exportHandler} />
            <LoadableButton
              onClick={importHandler}
              className='bg-primary text-sm text-white font-semibold px-4 py-2 rounded-lg themeHover  duration-500'
              img={<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-4 h-4">
                <path fill-rule="evenodd" d="M12 2.25a.75.75 0 0 1 .75.75v11.69l3.22-3.22a.75.75 0 1 1 1.06 1.06l-4.5 4.5a.75.75 0 0 1-1.06 0l-4.5-4.5a.75.75 0 1 1 1.06-1.06l3.22 3.22V3a.75.75 0 0 1 .75-.75Zm-9 13.5a.75.75 0 0 1 .75.75v2.25a1.5 1.5 0 0 0 1.5 1.5h13.5a1.5 1.5 0 0 0 1.5-1.5V16.5a.75.75 0 0 1 1.5 0v2.25a3 3 0 0 1-3 3H5.25a3 3 0 0 1-3-3V16.5a.75.75 0 0 1 .75-.75Z" clip-rule="evenodd" />
              </svg>
              }
              disabled={isImportLoading}
              lable='Import'
              isLoading={isImportLoading}
              loadingLable='Importing...'
            />
            {/* Hidden file input */}
            <input
              type="file"
              ref={fileInputRef}
              className="hidden"
              onChange={handleFileChange}
            />
          </div>
        </div>
      </div>

      <div className="border-[2px] border-[var(--theme-light)] rounded-xl pt-4 lg:pt-6 w-full mt-5">
        <div className="px-4 lg:px-6">
          <p className="text-[#2F2B3D] text-xl font-semibold">All Leads</p>
        </div>
        <div className="my-6">
          <TableWithSkeleton columns={[...columns, ...aditionColumns]} data={data} loading={isLoading} onChange={handleFilterChange} />
          <Pagination total={totalRecord} pageSize={pageSize} setPageSize={setPageSize} page={page} setPage={setPage} />
        </div>
      </div>

      {/* ---- Add lead modal ---- */}
      <ModalComponent isOpen={isOpenModal} setIsOpen={setIsOpenModal} title={`${isUpdate ? 'Update' : 'Add'} lead`} width={1200}>
        <Form className="w-full" autoComplete="off" form={form} initialValues={initialValues} onFinish={handleSubmit} onFieldsChange={handleFieldsChange}>
          <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-5">
            <div className="input-white">
              <label className="text-base text-[#2F2B3DCC] font-medium">First Name</label>
              <Form.Item name="firstName" rules={[{ required: true, message: "Please enter a first name." }]}>
                <Input placeholder="First name" className="mt-1 w-full py-2" value={initialValues?.firstName} />
              </Form.Item>
            </div>
            <div className="input-white">
              <label className="text-base text-[#2F2B3DCC] font-medium">Last Name</label>
              <Form.Item name="lastName" rules={[{ required: true, message: "Please enter a last name." }]}>
                <Input placeholder="Last name" className="mt-1 w-full py-2" value={initialValues?.lastName} />
              </Form.Item>
            </div>
            <div className="input-white">
              <label className="text-base text-[#2F2B3DCC] font-medium">Primary Email</label>
              <Form.Item name="email" rules={[{ required: true, type: "email", message: "Please enter a valid email.", }]}>
                <Input placeholder="Primary email" className="mt-1 w-full py-2" value={initialValues?.email} />
              </Form.Item>
            </div>
            <div className="input-white">
              <label className="text-base text-[#2F2B3DCC] font-medium">Phone No.</label>
              <Form.Item name="phoneNo" rules={[{ required: true, message: "Please enter a valid phone number." }]}>
                <Input type="number" placeholder="Phone no." className="mt-1 w-full py-2" value={initialValues?.phoneNo} />
              </Form.Item>
            </div>
            <div className="ant-select-selector-white">
              <label className="text-base text-[#2F2B3DCC] font-medium">Country</label>
              <Form.Item name="countryId" rules={[{ required: true, message: "Please select a country." }]}>
                <Select showSearch placeholder="Select a country" className="w-full mt-1" filterOption={filterOption} options={countries} defaultValue={initialValues?.countryId} />
              </Form.Item>
            </div>
            <div className="ant-select-selector-white">
              <label className="text-base text-[#2F2B3DCC] font-medium">Source</label>
              <Form.Item name="sourceId" rules={[{ required: true, message: "Please select a source." }]}>
                <Select placeholder="Select a source" className="w-full mt-1" options={sources} value={initialValues?.sourceId} />
              </Form.Item>
            </div>
            <div className="input-white">
              <label className="text-base text-[#2F2B3DCC] font-medium">Comment</label>
              <Form.Item name="comment" rules={[{ required: true, message: "Please enter a comment." }]}>
                <TextArea className="mt-1 py-2" placeholder="Comment" rows={1} value={initialValues.comment} />
              </Form.Item>
            </div>
          </div>
          <div className="flex justify-end mt-8">
            <LoadableButton
              className="bg-primary text-sm text-white font-semibold rounded-lg px-12 py-2 uppercase themeHover  duration-500"
              disabled={!isFormChanged || isAddLeadLoading}
              type="Submit"
              lable="Submit"
              isLoading={isAddLeadLoading}
              loadingLable={`${isUpdate ? 'Updating' : 'Adding'} lead...`}
            />
          </div>
        </Form>
      </ModalComponent>

      {/* ---- Lead details modal ---- */}
      <ModalComponent isOpen={isDetailsModalOpen} setIsOpen={setIsDetailsModalOpen} title='Lead Details' width={1200}>
        <div className='grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 gap-10 '>
          <div>
            <p className='text-base font-normal '>First Name</p>
            <p className='text-xl primary font-semibold  mt-2'>{selectedLead?.firstName || "--"}</p>
          </div>
          <div>
            <p className='text-base font-normal '>Last Name</p>
            <p className='text-xl primary font-semibold  mt-2'>{selectedLead?.lastName || "--"}</p>
          </div>
          <div>
            <p className='text-base font-normal '>Email</p>
            <p className='text-xl primary font-semibold  mt-2'>{selectedLead?.email || "--"}</p>
          </div>
          <div>
            <p className='text-base font-normal '>Mobile No.</p>
            <p className='text-xl primary font-semibold  mt-2'>{selectedLead?.phoneNo || "--"}</p>
          </div>
          <div>
            <p className='text-base font-normal '>Country Name</p>
            <p className='text-xl primary font-semibold  mt-2'>{selectedLead?.countryName || "--"}</p>
          </div>
          <div>
            <p className='text-base font-normal '>Source Name</p>
            <p className='text-xl primary font-semibold  mt-2'>{selectedLead?.sourceName || "--"}</p>
          </div>
          <div>
            <p className='text-base font-normal '>Status</p>
            <p className='text-xl primary font-semibold  mt-2'>{selectedLead?.status || "--"}</p>
          </div>
          <div>
            <p className='text-base font-normal '>Created Date</p>
            <p className='text-xl primary font-semibold  mt-2'>{moment(selectedLead?.createdDate).format('DD-MM-YYYY, hh:mm A') || "--"}</p>
          </div>
        </div>
        <div className="mt-10">
          <p className='text-base font-normal '>Comment</p>
          <p className='text-xl primary font-semibold  mt-2'>{selectedLead?.comment || "--"}</p>
        </div>
      </ModalComponent>
    </div>
  )
}