import React, { useEffect, useState } from 'react';
import { GetEmailTemplateNameApi, GetEmailVariablesApi, AddUpdateEmailTemplateApi } from "../../../api/request/emailTemplate.js";
import { Input, Select, Form, Spin } from "antd";
import LoadableButton from '../../../components/buttons/LoadableButton.jsx';
import Copy from "../../../components/copy/Copy.jsx";
import Support from "../../../assets/support/Support.jsx";
import toast from "react-hot-toast";
import ModalComponent from '../../../components/global/modal/ModalComponent.jsx';
import { useNavigate } from 'react-router-dom';

const { TextArea } = Input;

export default function AddEmailTemplate() {

    const [form] = Form.useForm();
    const navigate = useNavigate();
    const [isLoadingData, setIsLoadingData] = useState(false);
    const [emailTemplateNames, setEmailTemplateNames] = useState([]);
    const [templateInitialValues, setTemplateInitialValues] = useState({ EmailTemplateId: null, Subject: '', Body: '' });
    const [isLoadingAddEditTemplate, setIsLoadingAddEditTemplate] = useState(false);
    const [selectedEmailTemplateId, setSelectedEmailTemplateId] = useState('');
    const [variableDetails, setVariableDetails] = useState([]);
    const [exportHtml, setExportHtml] = useState(null);
    const [changeExport, setChangeExport] = useState(5);
    const [value, setValue] = useState({});
    const [isOpenVariableDetailsModal, setIsOpenVariableDetailsModal] = useState(false);

    const getEmailTemplateNames = async () => {
        try {
            setIsLoadingData(true);
            const { data } = await GetEmailTemplateNameApi();
            if (data?.success) {
                setEmailTemplateNames(data?.data?.map((item) => ({ value: item?.emailTemplateId, label: item?.name })));
            }
            setIsLoadingData(false);
        } catch (error) {
            setIsLoadingData(false);
        }
    }


    useEffect(() => {
        getEmailTemplateNames();
    }, []);

    const getVariableDetails = async () => {
        try {
            if (!selectedEmailTemplateId) {
                return;
            }
            const { data } = await GetEmailVariablesApi(selectedEmailTemplateId);
            if (data?.success) {
                setVariableDetails(data?.data);
            }
        } catch (error) { }
    }

    useEffect(() => {
        getVariableDetails();
    }, [selectedEmailTemplateId]);

    const handlehtml = (value) => {
        setIsLoadingAddEditTemplate(true);
        setChangeExport(changeExport + 5)
        setValue(value)
    }

    const handleSubmit = async (values) => {
        try {
            let params = {
                ...values,
                // Body: `${exportHtml}`
            }
            let apiHeader = { headers: { Accept: "application/json", "Content-Type": "multipart/form-data" } };
            const { data } = await AddUpdateEmailTemplateApi(params, apiHeader);
            if (data?.success) {
                toast.success(data?.message);
                Form.resetFields();
                setSelectedEmailTemplateId('');
                setExportHtml(null);
                setValue({});
                navigate('/email_template');
            }
            setIsLoadingAddEditTemplate(false);
        } catch (error) {
            toast.error(error?.response?.data?.error?.message);
            setIsLoadingAddEditTemplate(false);
        }
    }

    useEffect(() => {
        if (exportHtml) {
            handleSubmit();
        }
    }, [exportHtml])

    return (
        <div className='border-2 border-light rounded-xl p-4'>
            {
                isLoadingData ? (
                    <div className="flex justify-center flex-col my-[12%]">
                        <Spin size="large" />
                        <p className="primary text-center mt-2">Loading...</p>
                    </div>
                ) : (
                    <Form className='w-full' autoComplete="off" form={form} initialValues={templateInitialValues} onFinish={handleSubmit}>
                        <div className="grid sm:grid-cols-2 gap-5 ">
                            <div className='ant-select-selector-white'>
                                <label className='text-base text-[#2F2B3DCC] font-medium'>Email Template Type</label>
                                <Form.Item name="EmailTemplateId" rules={[{ required: true, message: 'Please select email template type.' }]}>
                                    <Select placeholder='Select email template type' className='w-full mt-1' options={emailTemplateNames} onChange={() => setSelectedEmailTemplateId(form.getFieldValue('EmailTemplateId'))} />
                                </Form.Item>
                            </div>
                            <div className='input-white'>
                                <label className='text-base text-[#2F2B3DCC] font-medium'>Email Subject</label>
                                <Form.Item name="Subject" rules={[{ required: true, message: 'Please enter email subject.' }]}>
                                    <Input placeholder="Email subject" className="mt-1 ps-4 py-2" />
                                </Form.Item>
                            </div>
                        </div>
                        <div className='input-white w-full mt-4'>
                            <label className='text-base text-[#2F2B3DCC] font-medium mb-2'>Email Body</label>
                            <Form.Item name="Body" rules={[{ required: true, message: 'Please enter email Body.' }]}>
                            <TextArea placeholder="Email Body" className="mt-1 ps-4 py-2"  rows={6} />
                            </Form.Item>
                            {/* <HTMLEditor data={templateForm.getFieldValue('Body')} setExportHtml={setExportHtml} changeExport={changeExport} /> */}
                        </div>
                        {selectedEmailTemplateId &&
                            <div className="flex items-center gap-2 mt-2">
                                <p className="primary font-semibold">Variable Details</p>
                                <div className="cursor-pointer" onClick={() => setIsOpenVariableDetailsModal(true)}><Support /></div>
                            </div>
                        }
                        <div className='flex justify-end items-center gap-5 mt-6'>
                            <LoadableButton
                                className='bg-primary text-sm text-white font-semibold rounded-lg px-12 py-2 uppercase themeHover  duration-500'
                                disabled={isLoadingAddEditTemplate}
                                type="Submit"
                                lable='Submit'
                                isLoading={isLoadingAddEditTemplate}
                                loadingLable='Adding template...'
                            />
                        </div>
                    </Form>
                )
            }

            {/* --- Variable details modal --- */}
            <ModalComponent isOpen={isOpenVariableDetailsModal} setIsOpen={setIsOpenVariableDetailsModal} title='Variable Details' width={800}>
                <div className='p-5'>
                    {
                        variableDetails?.length > 0 ? (
                            <div className='grid md:grid-cols-2 gap-5 '>
                                {
                                    variableDetails?.map((variable, index) => (
                                        <div key={index} className="flex items-center gap-1">
                                            <p className='text-base primary font-semibold'>{variable?.emailVariableName}</p>
                                            <Copy text={`{{${variable?.emailVariableName}}}`} />
                                        </div>
                                    ))
                                }
                            </div>
                        ) : (
                            <p className="text-gray-500 text-lg">Variable is not required for this email template.</p>
                        )
                    }
                </div>
            </ModalComponent>
        </div>
    )
}