import React, { useState, useEffect } from "react";
import Pagination from "../../../../components/global/pagination/Pagination.jsx";
import columns from "../../../../columns/wallet/Withdraw/pending.js";
import ModalComponent from "../../../../components/global/modal/ModalComponent.jsx";
import TableWithSkeleton from "../../../../components/global/table/TableWithSkeleton.jsx";
import ConfirmationModal from "../../../../components/global/modal/ConfirmationModal.jsx";
import Eye from "../../../../assets/Eye.jsx";
import { GetAllAccountApi, RequestAcceptRejectApi } from "../../../../api/request/wallet/withdraw.js";
import moment from "moment";
import toast from "react-hot-toast";
import Copytest from "../../../../components/copy/Copy.jsx"
import { DatePicker } from "antd";
import Copy from '../../../../components/copy/Copy.jsx';

const { RangePicker } = DatePicker;

export default function Withdraw({ search }) {
    const [pageSize, setPageSize] = useState(10);
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [requestRejection, setrequestRejection] = useState(false);
    const [requestAccept, setrequestAccept] = useState(false);
    const [totalRecord, setTotalRecord] = useState(0);
    const [selectedIBId, setSelectedIBId] = useState(null)
    const [data, setData] = useState([]);
    const [page, setPage] = useState(1);
    const [isReqLoading, setIsReqLoading] = useState(false);
    const [textAreaValue, setTextAreaValue] = useState('');
    const [withdrawdetails, setWithdrawdetails] = useState({});
    const [dates, setDates] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [sorter, setSorter] = useState({ SortBy: 'requestDate', SortOrder: '' });

    const truncateString = (str) => {
        let string = str?.toString();
        return string?.substring(0, 6) + '...';
    };

    const handleClick = (item) => {
        setSelectedIBId({ userId: item?.userId, TransactionId: item?.requestId });
        setIsOpenModal(true);
    };

    const Withdrawrequest = (item) => {
        setSelectedRowKeys([item?.requestId]);
        setrequestAccept(true);
    };

    const WithdrawrequestReject = (item) => {
        setSelectedRowKeys([item?.requestId]);
        setrequestRejection(true);
    };

    const getAllWithdraw = async () => {
        try {
            let params = {
                PageNumber: page,
                PageSize: pageSize,
                SearchText: search,
                FilterText: "pending",
                FromDate: dates.length === 2 ? dates[0].format('MM-DD-YYYY') : '',
                ToDate: dates.length === 2 ? dates[1].format('MM-DD-YYYY') : '',
                ...sorter
            }
            setIsLoading(true);
            const { data } = await GetAllAccountApi(new URLSearchParams(params).toString());
            if (data?.success) {
                setData(
                    data?.data?.map((item) => {
                        return {
                            ...item,
                            key: item?.requestId,
                            name: `${item?.firstName} ${item?.lastName}`,
                            balance: `$ ${item?.balance}`,
                            amount: `$ ${item?.amount}`,
                            requestDate: moment(item?.requestDate).format('DD-MM-YYYY, hh:mm A'),
                            actionDate: moment(item?.actionDate).format('DD-MM-YYYY, hh:mm A'),
                            actions: (
                                <div className="flex">
                                    <button type="button" onClick={() => { setWithdrawdetails(item); handleClick(item); }}><Eye /></button>
                                    <button className="mr-3 ml-3" onClick={() => Withdrawrequest(item)} ><img src="assets/icons/accept_icon.svg" className="min-w-6 max-w-6" alt="icon" /></button>
                                    <button onClick={() => WithdrawrequestReject(item)}><img src="assets/icons/delete_icon.svg" className="min-w-6 max-w-6" alt="icon" /></button>
                                </div>
                            ),
                            address: (
                                <div className="flex">

                                    <p>{truncateString(item?.thirdPartyWalletAddress)}</p>
                                    <Copytest text={item?.thirdPartyWalletAddress} />

                                </div>
                            )

                        };
                    })
                );
                setTotalRecord(data?.totalRecords);
            }
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
        }
    }

    const handleDateChange = (dates) => {
        setDates(dates);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        getAllWithdraw();
    };

    useEffect(() => {
        getAllWithdraw();
    }, [page, pageSize, search, dates, sorter]);

    const RequestHandlerApproved = async () => {

        try {
            setIsReqLoading(true);
            let params = {
                WithdrawalRequestId: selectedRowKeys,
                IsApprovedRejected: true
            }
            const { data } = await RequestAcceptRejectApi(params);
            if (data?.success) {
                toast.success(data?.message);
                // setIsOpenModal(false);
                setSelectedRowKeys([])
            }
            getAllWithdraw();
            setTextAreaValue('');
            setIsReqLoading(false);
        } catch (error) {
            toast.error(error?.response?.data?.error?.message);
            setIsReqLoading(false);
        }
        setrequestAccept(false);

    }

    const RequestHandlerReject = async (value) => {
        if (!value) {
            if (!textAreaValue) {
                toast.error('Please enter a reason.');
                return;
            }
        }
        try {
            setIsReqLoading(true);
            let params = {
                WithdrawalRequestId: selectedRowKeys,
                IsApprovedRejected: false,
                comment: textAreaValue,
            }
            const { data } = await RequestAcceptRejectApi(params);
            if (data?.success) {
                toast.success(data?.message);
                setSelectedRowKeys([])
            }
            getAllWithdraw();
            setTextAreaValue('');
            setIsReqLoading(false);
        } catch (error) {
            toast.error(error?.response?.data?.error?.message);
            setIsReqLoading(false);
        }
        setrequestRejection(false);

    }
    const handleTableChange = (pagination, filters, sorter) => {
        if (sorter?.order) {
            setSorter({ SortBy: sorter?.field, SortOrder: sorter?.order });
        } else {
            setSorter({ SortBy: 'requestDate', SortOrder: '' });
        }
    };

    const onSelectChange = (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    return (
        <div>
            <div className="border-[2px] border-[var(--theme-light)] rounded-xl pt-4 lg:pt-6 w-full mt-5">
                <div className="flex justify-between px-4 lg:px-6">
                    <p className="text-[#2F2B3D] text-xl font-semibold">Pending Withdraw</p>
                    <div className="flex">
                        {(selectedRowKeys.length > 0) && (<div className="flex justify-center items-center mr-3">
                            <div className="mt-1">
                                <button
                                    onClick={() => {
                                        setrequestAccept(true);
                                    }}
                                    className="bg-[#2D9B6333] text-sm text-[#2D9B63] font-semibold px-4 py-2 rounded-lg uppercase hover:bg-[#2D9B63] hover:text-white duration-500"
                                >
                                    Accept
                                </button>
                                <button
                                    onClick={() => {
                                        setrequestRejection(true);
                                    }}
                                    className="bg-[#F8222333] text-sm text-[#F82223] font-semibold px-4 py-2 rounded-lg uppercase hover:bg-[#F82223] hover:text-white duration-500 ml-4"
                                >
                                    Reject
                                </button>
                            </div>
                        </div>
                        )}
                        <form autoComplete="off" onSubmit={handleSubmit}>
                            <div>
                                <RangePicker className='border-light border-2 w-full mt-1 py-2' format='DD-MM-YYYY' onChange={handleDateChange} />
                            </div>
                        </form>
                    </div>
                </div>
                <div className="my-6">
                    <TableWithSkeleton columns={columns} data={data} rowSelection={rowSelection} loading={isLoading} onChange={handleTableChange}/>
                    <Pagination total={totalRecord} pageSize={pageSize} setPageSize={setPageSize} page={page} setPage={setPage} />
                </div>
            </div>

            {/* ---- Withdraw Information ---- */}
            <ModalComponent
                isOpen={isOpenModal}
                setIsOpen={setIsOpenModal}
                title='Withdraw Information'
                width={1300}
            >
                <div className='p-5'>
                    <div className='grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 gap-10 '>
                        {withdrawdetails?.firstName ? <div>
                            <p className='text-base font-normal'>First Name</p>
                            <p className='text-xl primary font-semibold mt-2'>{withdrawdetails?.firstName || '---'}</p>
                        </div> : ""}
                        {withdrawdetails?.lastName ? <div>
                            <p className='text-base font-normal'>Last Name</p>
                            <p className='text-xl primary font-semibold mt-2'>{withdrawdetails?.lastName || '---'}</p>
                        </div> : ""}
                        {withdrawdetails?.email ? <div>
                            <p className='text-base font-normal'>Email</p>
                            <p className='text-xl primary font-semibold mt-2'>{withdrawdetails?.email || '---'}</p>
                        </div> : ""}
                        {withdrawdetails?.withdrawType ? <div>
                            <p className='text-base font-normal'>withdraw Type</p>
                            <p className='text-xl primary font-semibold mt-2'>{withdrawdetails?.withdrawType || '---'}</p>
                        </div> : ""}
                        {withdrawdetails?.walletAccountNo ? <div>
                            <p className='text-base font-normal'>Wallet Account No.</p>
                            <p className='text-xl primary font-semibold mt-2'>{withdrawdetails?.walletAccountNo || '---'}</p>
                        </div> : ""}
                        {withdrawdetails?.bankAccountNumber ? <div>
                            <p className='text-base font-normal'>Bank Account Number</p>
                            <p className='text-xl primary font-semibold mt-2'>{withdrawdetails?.bankAccountNumber || '---'}</p>
                        </div> : ""}
                        {withdrawdetails?.balance ? <div>
                            <p className='text-base font-normal'>Wallet Balance</p>
                            <p className='text-xl primary font-semibold mt-2'>$ {withdrawdetails?.balance || '---'}</p>
                        </div> : ""}
                        {withdrawdetails?.amount ? <div>
                            <p className='text-base font-normal'>Amount</p>
                            <p className='text-xl primary font-semibold mt-2'>$ {withdrawdetails?.amount || '---'}</p>
                        </div> : ""}
                        {withdrawdetails?.requestDate ? <div>
                            <p className='text-base font-normal'>Created Time</p>
                            <p className='text-xl primary font-semibold mt-2'>{withdrawdetails?.requestDate ? moment(withdrawdetails?.requestDate).format('DD-MM-YYYY, hh:mm A') : '---'}</p>
                        </div> : ""}
                        {withdrawdetails?.bankBranchAddress ? <div>
                            <p className='text-base font-normal'>Bank Branch Address</p>
                            <p className='text-xl primary font-semibold mt-2'>{withdrawdetails?.bankBranchAddress || '---'}</p>
                        </div> : ""}
                        {withdrawdetails?.bankName ? <div>
                            <p className='text-base font-normal'>Bank Name</p>
                            <p className='text-xl primary font-semibold mt-2'>{withdrawdetails?.bankName || '---'}</p>
                        </div> : ""}
                        {withdrawdetails?.iban ? <div>
                            <p className='text-base font-normal'>IBAN</p>
                            <p className='text-xl primary font-semibold mt-2'>{withdrawdetails?.iban || '---'}</p>
                        </div> : ""}
                        {withdrawdetails?.ifscCode ? <div>
                            <p className='text-base font-normal'>IFSC Code</p>
                            <p className='text-xl primary font-semibold mt-2'>{withdrawdetails?.ifscCode || '---'}</p>
                        </div> : ""}
                        {withdrawdetails?.swifT_BIC_Code ? <div>
                            <p className='text-base font-normal'>SwifT BIC Code</p>
                            <p className='text-xl primary font-semibold mt-2'>{withdrawdetails?.swifT_BIC_Code || '---'}</p>
                        </div> : ""}
                        {withdrawdetails?.cryptoWalletAddress ? <div>
                            <p className='text-base font-normal'>Crypto Wallet Address</p>
                            <div className="flex items-center gap-3">
                                <p className='text-xl primary font-semibold mt-2'>{withdrawdetails?.cryptoWalletAddress || '---'}</p>
                                <Copy text={withdrawdetails?.cryptoWalletAddress} />
                            </div>
                        </div> : ""}
                        {withdrawdetails?.paypalEmail ? <div>
                            <p className='text-base font-normal'>Paypal Email</p>
                            <p className='text-xl primary font-semibold mt-2'>{withdrawdetails?.paypalEmail || '---'}</p>
                        </div> : ""}
                        {withdrawdetails?.upiId ? <div>
                            <p className='text-base font-normal'>UPI Id</p>
                            <div className="flex items-center gap-3">
                                <p className='text-xl primary font-semibold mt-2'>{withdrawdetails?.upiId || '---'}</p>
                                <Copy text={withdrawdetails?.upiId} />
                            </div>
                        </div> : ""}

                    </div>
                </div>
            </ModalComponent>

            {/* ---- Reject-request ---- */}
            <ConfirmationModal
                isOpen={requestRejection}
                setIsOpen={setrequestRejection}
                message='Are you sure you want to reject this request?'
                onConfirm={() => RequestHandlerReject(true)}
                isLoading={isReqLoading}
                loadingLabel='Rejecting request...'
                textArea={true}
                textAreaValue={textAreaValue}
                setTextAreaValue={setTextAreaValue}
                placeholder='Reason of rejection...'
            />


            {/* ---- Accept-request ---- */}
            <ConfirmationModal
                isOpen={requestAccept}
                setIsOpen={setrequestAccept}
                type='success'
                message='Are you sure you want to withdraw this request?'
                onConfirm={() => RequestHandlerApproved(true)}
                isLoading={isReqLoading}
                loadingLabel='Accepting request...'
            />
        </div>
    )
}
