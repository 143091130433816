import React, { useState, useEffect } from "react";
import Pagination from "../../components/global/pagination/Pagination.jsx";
import columns from "../../columns/MT5Account/MT5RejectAccount.js";
import TableWithSkeleton from "../../components/global/table/TableWithSkeleton.jsx";
import { GetAllAccountApi, } from "../../api/request/account.js";
import moment from "moment";

export default function Accounts({dates}) {

    const [isLoading, setIsLoading] = useState(false);
    const [pageSize, setPageSize] = useState(10);
    const [page, setPage] = useState(1);
    const [filter, setFilter] = useState([]);
    const [totalRecord, setTotalRecord] = useState(0);
    const [data, setData] = useState([]);
    const [sorter, setSorter] = useState({ SortBy: 'actionDate', SortOrder: '' });


    const getAllMT5 = async () => {
        try {
          let params = {
            PageNumber: page,
            PageSize: pageSize,
            SearchText: dates,
            FilterText: "Rejected",
            ...sorter
          }
          setIsLoading(true);
          const { data } = await GetAllAccountApi(new URLSearchParams(params).toString());
          if (data?.success) {
            setData(
              data?.data?.map((item)=>{
                return{
                  ...item,
                  balance:`$ ${item?.balance}`,
                  actionDate: (item?.actionDate ? moment(item?.actionDate).format("DD-MM-YYYY, hh:mm A") : null),
                };
              })
            );
            setTotalRecord(data?.totalRecords);
          }
          setIsLoading(false);
        } catch (error) {
          setIsLoading(false);
        }
      }
      const handleTableChange = (pagination, filters, sorter) => {
        if (sorter?.order) {
            setSorter({ SortBy: sorter?.field, SortOrder: sorter?.order });
        } else {
            setSorter({ SortBy: 'actionDate', SortOrder: '' });
        }
    };
    

      useEffect(() => {
        getAllMT5();
      }, [page, pageSize, dates, filter, sorter]);

    return (
        <div>
          
            <div className="border-[2px] border-[var(--theme-light)] rounded-xl pt-4 lg:pt-6 w-full mt-5">
                <div className="px-4 lg:px-6">
                    <p className="text-[#2F2B3D] text-xl font-semibold">
                    MT5 Reject.
                    </p>
                </div>
                <div className="my-6">
                    <TableWithSkeleton columns={columns} data={data} loading={isLoading} onChange={handleTableChange}/>
                    <Pagination total={totalRecord} page={page} setPage={setPage} pageSize={pageSize} setPageSize={setPageSize} />
                </div>
            </div>

          
          
        </div>
    )
}