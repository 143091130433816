import React, { useState, useEffect } from "react";
import BarChart from "../../components/global/charts/BarChart.jsx";
import { GetAllDashboardApi } from "../../api/request/dashboard.js";
import { presets } from '../../utils/RangePickerPresets.js';
import { DatePicker, Spin } from 'antd';
import StatisticsCard from "../../components/global/cards/StatisticsCardCard.jsx";
import ActualPNLCard from "./components/ActualPNLCard.jsx";
import RadialBarChart from "../../components/global/charts/RadialBarChart.jsx";
import useWebSocket from 'react-use-websocket';
import Accounts from "../../assets/dashboard/Accounts.jsx";
import KYC from "../../assets/dashboard/KYC.jsx";
import Online from "../../assets/dashboard/Online.jsx";
import InActive from "../../assets/dashboard/InActive.jsx";
import TotalUser from "../../assets/dashboard/TotalUser.jsx";
import Active from "../../assets/dashboard/Active.jsx";
import moment from "moment/moment.js";
import { Link } from "react-router-dom";
const { RangePicker } = DatePicker;

const Dashboard = () => {

  const [isLoading, setIsLoading] = useState(false);
  const [dashboardData, setDashboardData] = useState(0);
  const [socketData, setSocketData] = useState({});
  const [depositWithdrawDateRange, setDepositWithdrawDateRange] = useState({ fromDate: '', toDate: '' });
  const [depositWithdrawLoading, setDepositWithdrawLoading] = useState(false);
  const primaryColor = (getComputedStyle(document.documentElement).getPropertyValue('--theme'))?.trim();
  const secondary = getComputedStyle(document.documentElement).getPropertyValue('--theme2')?.trim();
  const [depositWithdrawalData, setDepositWithdrawalData] = useState([]);
  const { lastJsonMessage } = useWebSocket(`wss://metatrader5.pioneercapitalmarkets.com/api/OnlineUserCount/Get-OnlineUserCount`);
  const [dashboardFatched, setDashboardFatched] = useState(false);

  useEffect(() => {
    setSocketData(lastJsonMessage);
  }, [lastJsonMessage]);

  const manageBarCharData = (data) => {
    let depositData = [];
    let withdrawData = [];
    let dateData = [];
    data?.map((item) => {
      depositData?.push(item?.totalDeposit);
      withdrawData?.push(item?.totalWithdrawal);
      dateData?.push(`${moment(item?.date).format('MMM DD')}`);
    });
    setDepositWithdrawalData({ categories: dateData, series: [{ name: 'Total Deposit', data: depositData, prefix: '$', postfix: '' }, { name: 'Total Withdraw', data: withdrawData, prefix: '$', postfix: '' }] });
  }

  const getAllDashboard = async () => {
    try {
      setIsLoading(true);
      let params = {
        FromDate: depositWithdrawDateRange?.fromDate,
        ToDate: depositWithdrawDateRange?.toDate,
      }
      const { data } = await GetAllDashboardApi(new URLSearchParams(params).toString());
      if (data?.success) {
        setDashboardData(data?.data);
        manageBarCharData(data?.data?.depositWithdrawalList);
      }
      setIsLoading(false);
      setDashboardFatched(true);
    } catch (error) {
      setIsLoading(false);
    }
  }

  const getDepositWithdrawData = async () => {
    try {
      setDepositWithdrawLoading(true);
      let params = {
        FromDate: depositWithdrawDateRange?.fromDate,
        ToDate: depositWithdrawDateRange?.toDate,
      }
      const { data } = await GetAllDashboardApi(new URLSearchParams(params).toString());
      if (data?.success) {
        manageBarCharData(data?.data?.depositWithdrawalList);
      }
      setDepositWithdrawLoading(false);
    } catch (error) {
      setDepositWithdrawLoading(false);
    }
  }

  useEffect(() => {
    getAllDashboard();
  }, []);

  useEffect(() => {
    if (dashboardFatched) {
      getDepositWithdrawData();
    }
  }, [depositWithdrawDateRange]);

  const handleDateChange = (value) => {
    if (value && value.length === 2) {
      const fromDate = value[0].format('MM/DD/YYYY');
      const toDate = value[1].format('MM/DD/YYYY');
      setDepositWithdrawDateRange({ fromDate: fromDate, toDate: toDate });
    }
    if (!value || value.length === 0) {
      setDepositWithdrawDateRange({ fromDate: '', toDate: '' });
    }
  };

  const disabled7DaysDate = (current, { from }) => {
    if (from) {
      return Math.abs(current.diff(from, 'days')) >= 7;
    }
    return false;
  };

  const calculatePercentage = (a, b) => {
    const total = a + b;
    const percentageA = (a / total) * 100;
    return (percentageA.toFixed(2));
  }

  return (
    <div>
      {isLoading ? (
        <div className="flex justify-center flex-col mt-[20%]">
          <Spin size="large" />
          <p className="primary text-center mt-2">Loading...</p>
        </div>
      ) : (
        <div>
          <div className="grid sm:grid-cols-2 xl:grid-cols-3 gap-5 mb-5">

            <div className="order-2 xl:order-1">
              <ActualPNLCard amount={socketData?.MT5ActualProfitLoss} />
            </div>

            <div className="sm:col-span-2 xl:order-2 order-1">
              <div className="grid sm:grid-cols-3 gap-4">
                <Link to="/accounts">
                  <StatisticsCard title='Total Accounts' value={socketData?.MT5TotalAccounts} icon={<TotalUser />} />
                </Link>
                <Link to="/accounts">
                  <StatisticsCard secondary={true} title='Active Accounts' value={socketData?.MT5ActiveAccounts} icon={<Active />} />
                </Link>
                <Link to="/inactive_Accounts">
                  <StatisticsCard title='Inactive Accounts' value={socketData?.MT5InactiveAccounts} icon={<InActive />} />
                </Link>
                <Link to="/terminal_Online_Users">
                  <StatisticsCard title='Terminal Online Users' secondary={true} value={socketData?.MT5OnlineUsers} icon={<Online />} />
                </Link>
                <Link to="/accounts">
                  <StatisticsCard title='Pending Accounts' value={dashboardData?.liveAccPending} icon={<Accounts />} />
                </Link>
                <Link to="/kyc?filter=Pending">
                  <StatisticsCard title='Pending KYC' secondary={true} value={dashboardData?.kycPending} icon={<KYC />} />
                </Link>
              </div>
            </div>

            <div className="sm:col-span-2 order-4 xl:order-3">
              <div className="border-2 border-light sm:p-4 rounded-[10px]">
                <div className="mb-3 flex flex-wrap items-center justify-between gap-4">
                  <div className="flex items-center">
                    <div className="flex items-center flex-wrap">
                      <div className="flex items-center pl-6">
                        <div className="h-[10px] w-[10px] bg-primary  rounded-[50%]"></div>
                        <p className="ml-2 text-xl font-medium text-[#07111CCC]">Deposit</p>
                      </div>
                      <div className="flex items-center ml-3">
                        <div className="h-[10px] w-[10px] bg-[var(--theme2)]  rounded-[50%]"></div>
                        <p className="ml-2 text-xl font-medium text-[#07111CCC]">Withdraw</p>
                      </div>
                    </div>
                  </div>
                  <div className='ant-date-range-selector-white'>
                    <RangePicker disabledDate={disabled7DaysDate} className='py-2' onChange={(value) => handleDateChange(value)} presets={presets} format='DD-MM-YYYY' />
                  </div>
                </div>
                {
                  depositWithdrawLoading ? (
                    <div className="flex justify-center flex-col my-[20%]">
                      <Spin size="large" />
                      <p className="primary text-center mt-2">Loading...</p>
                    </div>
                  ) : (
                    <BarChart data={depositWithdrawalData} colors={[primaryColor, secondary]} />
                  )
                }
              </div>
            </div>

            <div className='border-2 border-light p-3 sm:p-4 rounded-[10px] xl:order-4 order-3'>
              <p className='text-xl font-medium mb-4'>IB Overview</p>
              <RadialBarChart values={[calculatePercentage(dashboardData?.ibRequestApproved, dashboardData?.ibRequestPending), 100 - calculatePercentage(dashboardData?.ibRequestApproved, dashboardData?.ibRequestPending)]} labels={['Approved', 'Pending']} colors={[primaryColor, secondary]} />
              <div className='flex justify-between gap-4 items-center'>
                <div className='flex items-center'><div className='h-[10px] w-[10px] bg-primary rounded-full'></div><p className='ml-2 text-sm font-medium text-[#07111CCC]'>Approved Request : {dashboardData?.ibRequestApproved}</p></div>
                <div className='flex items-center'><div className='h-[10px] w-[10px] bg-secondary rounded-full'></div><p className='ml-2 text-sm font-medium text-[#07111CCC]'>Pending Request : {dashboardData?.ibRequestPending}</p></div>
              </div>
            </div>
          </div>

          <div className="grid lg:grid-cols-2 gap-5 mb-5">
            <div className='border-2 border-light rounded-[10px] cursor-pointer p-6'>
              <p className='font-semibold text-lg uppercase text-[#2F2B3D]'>Total IB Commission</p>
              <div className="grid grid-cols-3 gap-5">
                <div>
                  <p className='font-semibold text-md uppercase text-[#00000080] mt-4'>Generate :</p>
                  <p className="font-bold text-2xl mt-1 primary">$ {dashboardData?.ibCommisionGen || 0}</p>

                </div>
                <div>
                  <p className='font-semibold md:text-md uppercase text-[#00000080] mt-4'>Withdraw :</p>
                  <p className="font-bold text-2xl mt-1 secondary">$ {dashboardData?.ibCommisionWD || 0}</p>
                </div>
                <div>
                  <p className='font-semibold md:text-md uppercase text-[#00000080] mt-4'>Admin :</p>
                  <p className="font-bold text-2xl mt-1 secondary">$ {dashboardData?.adminCommissionGen || 0}</p>
                </div>
              </div>
            </div>

            <div className='border-2 border-light rounded-[10px] cursor-pointer p-6'>
              <p className='font-semibold text-lg uppercase text-[#2F2B3D]'>Total Deposit - withdraw</p>
              <div className="grid grid-cols-2 gap-5">
                <div>
                  <p className='font-semibold md:text-md uppercase text-[#00000080] mt-4'>Deposit :</p>
                  <p className="font-bold text-2xl mt-1 primary">$ {dashboardData?.totalDeposit || 0}</p>
                </div>
                <div>
                  <p className='font-semibold text-md uppercase text-[#00000080] mt-4'>Withdraw :</p>
                  <p className="font-bold text-2xl mt-1 secondary">$ {dashboardData?.totalWithdraw || 0}</p>
                </div>
              </div>
            </div>
          </div>
        </div>)}
    </div>
  );
};

export default Dashboard;