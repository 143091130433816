import React, { useEffect, useState } from 'react';
import columns from '../../../columns/ibApproved/ibHandler.js';
import commissionListColumns from '../../../columns/ibApproved/ibCommissionList.js';
import TableWithSkeleton from '../../../components/global/table/TableWithSkeleton';
import ModalComponent from '../../../components/global/modal/ModalComponent.jsx';
import { GetIBCommissionTreeApi, UpdateIBCommissionTreeApi } from "../../../api/request/ibapproved.js";
import { useParams } from 'react-router-dom';
import Edit from '../../../assets/Edit.jsx';
import { Form, Input } from 'antd';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import LoadableButton from '../../../components/buttons/LoadableButton.jsx';
import AssignNewPlanToIB from "../../client/components/AssignNewPlanToIB.jsx"

export default function IbHandler() {

    const [form] = Form.useForm();
    const { id } = useParams();
    const userDetails = useSelector((state) => state.user.userDetails);
    const primaryColor = (getComputedStyle(document.documentElement).getPropertyValue('--theme'))?.trim();
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState([]);
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [commissionData, setCommissionData] = useState([]);
    const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
    const [initialValues, setInitialValues] = useState({});
    const [isAssignIBPlan, setIsAssignIBPlan] = useState(false);

    const openEditModal = (ib) => {
        setInitialValues(ib?.accountsList?.reduce((acc, item) => {
            acc[item.commissionId] = item.ibCommission || 0;
            return acc;
        }, {}));

        setCommissionData(
            ib?.accountsList?.map((item) => {
                return {
                    ...item,
                    commissionAmount: (
                        <Form.Item name={item?.commissionId} rules={[
                            { validator: (_, value) => value > item.parentIBCommission ? Promise.reject(new Error(`Maximum commission should be ${item.parentIBCommission}.`)) : Promise.resolve(), },
                            { validator: (_, value) => value < item.childIBCommission ? Promise.reject(new Error(`Minimum commission should be ${item.childIBCommission}.`)) : Promise.resolve(), },
                        ]} >
                            <Input
                                prefix={<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill={primaryColor} class="size-6">
                                    <path d="M10.464 8.746c.227-.18.497-.311.786-.394v2.795a2.252 2.252 0 0 1-.786-.393c-.394-.313-.546-.681-.546-1.004 0-.323.152-.691.546-1.004ZM12.75 15.662v-2.824c.347.085.664.228.921.421.427.32.579.686.579.991 0 .305-.152.671-.579.991a2.534 2.534 0 0 1-.921.42Z" />
                                    <path fill-rule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25ZM12.75 6a.75.75 0 0 0-1.5 0v.816a3.836 3.836 0 0 0-1.72.756c-.712.566-1.112 1.35-1.112 2.178 0 .829.4 1.612 1.113 2.178.502.4 1.102.647 1.719.756v2.978a2.536 2.536 0 0 1-.921-.421l-.879-.66a.75.75 0 0 0-.9 1.2l.879.66c.533.4 1.169.645 1.821.75V18a.75.75 0 0 0 1.5 0v-.81a4.124 4.124 0 0 0 1.821-.749c.745-.559 1.179-1.344 1.179-2.191 0-.847-.434-1.632-1.179-2.191a4.122 4.122 0 0 0-1.821-.75V8.354c.29.082.559.213.786.393l.415.33a.75.75 0 0 0 .933-1.175l-.415-.33a3.836 3.836 0 0 0-1.719-.755V6Z" clip-rule="evenodd" />
                                </svg>}
                                type='number'
                                className='py-2'
                                placeholder='Enter commission'
                            />
                        </Form.Item>
                    )
                }
            })
        );

        setIsOpenModal(true);
    }

    const closeEditModal = () => {
        form.resetFields();
        setInitialValues(null);
        setCommissionData([]);
        setIsOpenModal(false);
    }

    const convertDataToTree = (data) => {
        const traverse = (node) => {
            return {
                key: `${node?.userId}`,
                name: node?.fullName,
                accountsList: node?.accountsList,
                count: node?.userCount || 0,
                email: node?.email,
                level: node?.level,
                referralCode: node?.referralCode,
                affiliateCode: node?.affiliateCode,
                userType: node?.userType,
                children: node?.childrenList?.length ? node?.childrenList?.map(traverse) : null,
                action: userDetails?._id != node?.userId ? <button onClick={() => openEditModal(node)}><Edit /></button> : '--'
            };
        };
        return [traverse(data)];
    };

    const getIBCommission = async () => {
        try {
            setIsLoading(true);
            const { data } = await GetIBCommissionTreeApi(id);
            if (data?.success) {
                setData(convertDataToTree(data?.data[0]));
            }
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        getIBCommission();
    }, []);

    const handleSubmit = async (values) => {
        try {
            setIsLoadingSubmit(true);

            let commissionArray = [];
            Object.keys(initialValues).forEach(key => {
                const initialCommission = Number(initialValues[key]);
                const updatedCommission = Number(values[key]);

                if (initialCommission !== updatedCommission) {
                    commissionArray.push({
                        commissionId: key,
                        commissionAmount: Number(values[key])
                    });
                }
            });
            if (commissionArray?.length) {
                const { data } = await UpdateIBCommissionTreeApi(commissionArray);
                if (data?.success) {
                    toast.success(data?.message);
                    closeEditModal();
                    getIBCommission();
                }
            } else {
                closeEditModal();
            }
            setIsLoadingSubmit(false);
        } catch (error) {
            if (error?.response?.data?.error?.message) {
                toast.error(error?.response?.data?.error?.message);
            }
            setIsLoadingSubmit(false);
        }
    }

    useEffect(() => {
        if (!isOpenModal) {
            closeEditModal();
        }
    }, [isOpenModal]);

    return (
        <div>
            <div className="border-[2px] border-[var(--theme-light)] rounded-xl pt-4 lg:pt-6 w-full mt-5">
                <div className="px-4 lg:px-6">
                    <p className="text-[#2F2B3D] text-xl font-semibold">IB Details</p>
                </div>
                <div className="my-6">
                    <TableWithSkeleton columns={columns} data={data} loading={isLoading} />
                </div>
            </div>

            {initialValues &&
                <ModalComponent isOpen={isOpenModal} setIsOpen={setIsOpenModal} title='Update commission' width={800}>
                    <div className='flex justify-end mb-5'>
                    <button className='bg-primary text-sm text-white font-semibold px-6 py-2 rounded-lg themeHover duration-500' onClick={() => { setIsAssignIBPlan(true) }}>Assign New Plan To IB</button>
                    </div>
                    <Form form={form} initialValues={initialValues} onFinish={handleSubmit}>
                        <TableWithSkeleton columns={commissionListColumns} data={commissionData} />
                        {
                            commissionData?.length > 0 &&
                            <div className='mt-6 mx-6 flex justify-end'>
                                <Form.Item>
                                    <div className='flex items-center justify-end gap-5'>
                                        <button type='button' onClick={closeEditModal} className='bg-secondary text-sm text-white font-semibold px-6 py-2 rounded-lg  duration-500'>Cancel</button>
                                        <LoadableButton
                                            type='submit'
                                            className='bg-primary text-sm text-white font-semibold px-6 py-2 rounded-lg themeHover duration-500'
                                            lable='Save'
                                            loadingLable='Updating commission...'
                                            isLoading={isLoadingSubmit}
                                        />
                                    </div>
                                </Form.Item>
                            </div>
                        }
                    </Form>
                </ModalComponent>
            }
            <AssignNewPlanToIB isAssignIBPlan={isAssignIBPlan} setIsAssignIBPlan={setIsAssignIBPlan} id={id} setIsOpenModal={setIsOpenModal} getIBCommission={getIBCommission} />
        </div>
    )
}