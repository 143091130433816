import React, { useState, useEffect } from "react";
import { Select, Input, Spin } from "antd";
import columns from "../../../columns/support/Ticket.js";
import Pagination from "../../../components/global/pagination/Pagination.jsx";
import TableWithSkeleton from "../../../components/global/table/TableWithSkeleton.jsx";
import { GetAllTicketApi, GetTicketCountApi } from "../../../api/request/ticket.js";
import { useNavigate, useSearchParams } from 'react-router-dom';
import Eye from "../../../assets/Eye.jsx";
import StatisticsCard from "../../../components/global/cards/StatisticsCardCard.jsx";
import Online from "../../../assets/dashboard/Online.jsx";

export default function Ticket() {

  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [pageSize, setPageSize] = useState(10);
  const [isLoading, setIsLoading] = useState(false);
  const [userTypeSelectorOptions, setUserTypeSelectorOptions] = useState([{ value: "", label: "All", }, { value: "Open", label: "Open Tickets", }, { value: "Closed", label: "Closed Tickets", }]);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [searchText, setSearchText] = useState('');
  const [filter, setFilter] = useState('All Tickets');
  const [filterValue, setFilterValue] = useState('');
  const [totalRecord, setTotalRecord] = useState(0);
  const [selectedUserType, setSelectedUserType] = useState("");
  const [isTicketCountLoading, setIsTicketCountLoading] = useState(false);
  const [ticketFilter, setTicketFilter] = useState([
    { title: 'All Tickets', count: '0', value: '', icon: <Online /> },
    // { title: 'Open Tickets', count: '0', value: 'Open', icon: <Online /> },
    { title: 'High Priority Tickets', count: '0', value: 'High', icon: <Online /> },
    { title: 'This Month Tickets', count: '0', value: 'ThisMonth', icon: <Online /> },
    // { title: 'Unassigned Tickets', count: '0', value: 'UnAssign',icon:<Accounts />},
    { title: 'Closed Tickets', count: '0', value: 'Closed', icon: <Online /> },
    { title: 'Tickets pass by IB', count: '0', value: 'Assigned', icon: <Online /> },
  ]);

  useEffect(() => {
    if (searchParams?.get("filter")?.trim()) {
      setFilter(searchParams?.get("filter")?.trim());
    }
    getTicketCount();
  }, []);

  useEffect(() => {
    setSearchParams(`filter=${filter?.toString()}`);
  }, [filter]);

  const handleChange = (selectedOption) => {
    setSelectedUserType(selectedOption);
  }


  const getColor = (priority) => {
    switch (priority) {
      case 'High':
        return '#ef6464';
      case 'Medium':
        return '#e7c553';
      case 'Low':
        return '#2d9b63';
      case 'Open':
        return '#2d9b63';
      case 'Closed':
        return '#ef6464';
    }
  }

  const getTicketCount = async () => {
    try {
      setIsTicketCountLoading(true);
      const { data } = await GetTicketCountApi();
      if (data?.success) {
        setTicketFilter([
          { title: 'All Tickets', count: data?.data?.allTickets, value: '', icon: <Online /> },
          // { title: 'Open Tickets', count: data?.data?.openTickets, value: 'Open', icon: <Online /> },
          { title: 'High Priority Tickets', count: data?.data?.highPriorityTickets, value: 'High', icon: <Online /> },
          { title: 'This Month Tickets', count: data?.data?.thisMonthTickets, value: 'ThisMonth', icon: <Online /> },
          // { title: 'Unassigned Tickets', count: data?.data?.unassignedTickets, value: 'UnAssign',icon:<Online /> },
          // { title: 'Closed Tickets', count: data?.data?.closedTickets, value: 'Closed', icon: <Online /> },
          { title: 'Tickets pass by IB', count: data?.data?.adminAssigned, value: 'Assigned', icon: <Online /> },
        ]);
      }
      setIsTicketCountLoading(false);
    } catch (error) {
      setIsTicketCountLoading(false);
    }
  }

  const joinStrings = (array) => {
    return array.filter(item => item).join(', ');
  }
  console.log(filter, "filter");

  const getAllTicket = async () => {

    try {
      let params = {
        PageNumber: page,
        PageSize: pageSize,
        TicketStatus: selectedUserType,
        FilterText: filter === "All Tickets"
          ? ''
          : filter === "Open Tickets"
            ? 'Open'
            : filter === "High Priority Tickets"
              ? 'High'
              : filter === "This Month Tickets"
                ? 'ThisMonth'
                : filter === "Unassigned Tickets"
                  ? 'UnAssign'
                  : filter === "Closed Tickets"
                    ? 'Closed'
                    : '',
        SearchText: searchText,
        Notify: filter === "Tickets pass by IB" ? "True" : " "
      }
      setIsLoading(true);
      const { data } = await GetAllTicketApi(new URLSearchParams(params).toString());

      if (data?.success) {
        setData(
          data?.data?.map((item) => {
            return {
              ...item,
              name: `${item?.firstName} ${item?.lastName}`,
              status: (<p className='uppercase font-bold text-sm' style={{ color: getColor(item?.status) }}>{item?.status}</p>),
              actions: (<button type="button" onClick={() => navigate(`/view_ticket/${item?.ticketId}`)}><Eye /></button>),
              priority: (<p className='uppercase font-bold text-sm' style={{ color: getColor(item?.priority) }}>{item?.priority}</p>),
              createby: (<p className='uppercase font-bold text-sm' style={{ color: getColor(item?.notifyToAdmin) }}>{item?.notifyToAdmin === false ? "User" : "IB"}</p>),
            };
          })
        );
        setTotalRecord(data?.totalRecords);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }

  }

  useEffect(() => {

    getAllTicket();

  }, [page, pageSize, searchText, filter, selectedUserType]);


  useEffect(() => {
    setPage(1);
  }, [filterValue, selectedUserType]);

  return (
    <div>
      <div>
        {isTicketCountLoading ? (
          <div className="flex justify-center flex-col my-14">
            <Spin size="large" />
            <p className="primary text-center mt-2">Loading...</p>
          </div>
        ) : (
          <div className="grid grid-cols-5">
            <div className="grid sm:grid-cols-2 md:grid-cols-4 gap-5 col-span-6">
              {
                ticketFilter?.map((item, index) => (
                  <div key={index} onClick={() => { setFilterValue(item?.value); setFilter(item?.title) }}>
                    <StatisticsCard title={item?.title} value={item?.count} secondary={index % 2 != 0} active={filter == item?.title} icon={item?.icon} />
                  </div>
                ))
              }
            </div>
          </div>
        )}
      </div>

      <div className="border-[2px] border-light rounded-xl pt-4 lg:pt-6 w-full mt-7">
        <div className="flex justify-between px-4 lg:px-6">
          <p className="text-[#2F2B3D] text-xl font-semibold m-0">
            Ticket
          </p>
          <div className="flex items-center flex-wrap gap-5">
            <div>
              <Input
                size="large"
                style={{ width: 220 }}
                placeholder="Search"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                onPressEnter={() => setSearchText(search)}
                prefix={
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-5 h-5">
                    <path fill-rule="evenodd" d="M10.5 3.75a6.75 6.75 0 1 0 0 13.5 6.75 6.75 0 0 0 0-13.5ZM2.25 10.5a8.25 8.25 0 1 1 14.59 5.28l4.69 4.69a.75.75 0 1 1-1.06 1.06l-4.69-4.69A8.25 8.25 0 0 1 2.25 10.5Z" clip-rule="evenodd" />
                  </svg>
                }
                suffix={search &&
                  <svg xmlns="http://www.w3.org/2000/svg" onClick={() => { setSearchText(''); setSearch('') }} viewBox="0 0 24 24" fill="#bebebe" className="size-5 cursor-pointer duration-300 hover:fill-[#969595]">
                    <path fill-rule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm-1.72 6.97a.75.75 0 1 0-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 1 0 1.06 1.06L12 13.06l1.72 1.72a.75.75 0 1 0 1.06-1.06L13.06 12l1.72-1.72a.75.75 0 1 0-1.06-1.06L12 10.94l-1.72-1.72Z" clip-rule="evenodd" />
                  </svg>
                }

              />
            </div>
            <div className='ant-select-selector-white'>
              <Select
                className="w-[180px] -mt-2"
                placeholder="Select user type"
                options={userTypeSelectorOptions}
                onChange={handleChange}
                value={selectedUserType}
              />
            </div>
          </div>
        </div>
        <div className="my-6">
          <TableWithSkeleton columns={columns} data={data} loading={isLoading} />
          <Pagination total={totalRecord} page={page} setPage={setPage} pageSize={pageSize} setPageSize={setPageSize} />
        </div>

      </div>
    </div>
  );
}
