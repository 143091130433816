import React, { useState, useEffect } from "react";
import columns from "../../../columns/NewSymbol.js";
import TableWithSkeleton from "../../../components/global/table/TableWithSkeleton.jsx";
import { GetAllSymbolApi} from "../../../api/request/settings/Symbol.js";
import { useNavigate} from 'react-router-dom';
import Eye from "../../../assets/Eye.jsx";


export default function Symbol() {
    const navigate = useNavigate();
    const [pageSize, setPageSize] = useState(10);
    const [isLoading, setIsLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [totalRecord, setTotalRecord] = useState(0);
 
    const [data, setData] = useState([]);

    const getAllSymbol = async () => {
        try {
            let params = {
                PageNumber: page,
                PageSize: pageSize,
            }
            setIsLoading(true);
            const { data } = await GetAllSymbolApi(new URLSearchParams(params)?.toString());
            if (data?.success) {

                setData(
                    data?.data?.map((item) => {
                        return {
                            ...item,
                            actions: (<button type="button" onClick={() => navigate(`/symbol_detail/Name=${encodeURIComponent(item?.groupName)}`)}><Eye /></button>),
                        };
                    })
                );
                setTotalRecord(data?.totalRecords);
            }
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        getAllSymbol();
    }, [page, pageSize]);

    return (
        <div>
            <div className="border-[2px] border-[var(--theme-light)] rounded-xl pt-4 lg:pt-6 w-full mt-5">
                <div className="px-4 lg:px-6">
                    <p className="text-[#2F2B3D] text-xl font-semibold">All Symbol</p>
                </div>
                <div className="mt-6">
                    <TableWithSkeleton columns={columns} data={data} loading={isLoading} />
                    {/* <Pagination total={totalRecord} pageSize={pageSize} setPageSize={setPageSize} /> */}
                </div>


            </div>
        </div>
    );
}


