import React, { useState, useEffect } from "react";
import { Input, DatePicker } from "antd";
import Pagination from "../../../components/global/pagination/Pagination.jsx";
import columns from "../../../columns/wallet/InternalTransfer.js";
import TableWithSkeleton from "../../../components/global/table/TableWithSkeleton.jsx";
import { GetAllwalletinternalTransferApi, InternalTransferRequestAcceptRejectApi } from "../../../api/request/wallet/internalTransfer.js";
import moment from "moment";
import Segmented from "../../../components/segmented/Segmented.jsx";
import Eye from "../../../assets/Eye.jsx";
import ModalComponent from "../../../components/global/modal/ModalComponent.jsx";
import ConfirmationModal from "../../../components/global/modal/ConfirmationModal.jsx";
import toast from "react-hot-toast";
import ExportHandler from "../../../components/global/export/ExportHandler.jsx";
const { RangePicker } = DatePicker;

export default function InternalTransfer() {

  const [pageSize, setPageSize] = useState(10);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [totalRecord, setTotalRecord] = useState(0);
  const [page, setPage] = useState(1);
  const [searchText, setSearchText] = useState('');
  const [search, setSearch] = useState('');
  const [dates, setDates] = useState([]);
  const [value, setValue] = useState("Pending");
  const [selectedInternalTransfer, setselectedInternalTransfer] = useState("");
  const [selectedInternalTransferid, setselectedInternalTransferId] = useState("");
  const [InternalTransferAccept, setInternalTransferAccept] = useState(false);
  const [InternalTransferRejection, setInternalTransferRejection] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isReqLoading, setIsReqLoading] = useState(false);
  const [textAreaValue, setTextAreaValue] = useState("");
  const [sorter, setSorter] = useState({ SortBy: 'actionDate', SortOrder: '' });

  const getAllInternalTransfer = async () => {
    try {
      let params = {
        PageNumber: page,
        PageSize: pageSize,
        SearchText: searchText,
        FromDate: dates.length === 2 ? dates[0].format('MM-DD-YYYY') : '',
        ToDate: dates.length === 2 ? dates[1].format('MM-DD-YYYY') : '',
        FilterText: value === "Pending"
          ? "Pending"
          : value === "Approved"
            ? "Success"
            : value === "Declined"
              ? "Rejected"
              : value,
        ...sorter
      }
      setIsLoading(true);
      const { data } = await GetAllwalletinternalTransferApi(new URLSearchParams(params).toString());
      if (data?.success) {
        setData(
          data?.data?.map((item) => {
            return {
              ...item,

              name: `${item?.firstName} ${item?.lastName}`,
              amount: `$ ${item?.amount}`,
              transactionDate: item?.transactionDate ? moment(item?.transactionDate).format('DD-MM-YYYY, hh:mm A') : '--',
              actionDate: item?.actionDate ? moment(item?.actionDate).format('DD-MM-YYYY, hh:mm A') : '--',
              actions: (<button type="button" onClick={() => { setselectedInternalTransfer(item); setIsOpenModal(true); }}><Eye /></button>),
            };
          })
        );
        setTotalRecord(data?.totalRecords);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  }
  const handleTableChange = (pagination, filters, sorter) => {
    if (sorter?.order) {
        setSorter({ SortBy: sorter?.field, SortOrder: sorter?.order });
    } else {
        setSorter({ SortBy: 'actionDate', SortOrder: '' });
    }
};

  useEffect(() => {
    getAllInternalTransfer();
  }, [page, pageSize, searchText, dates, value, sorter]);

  const exportHandler = async () => {
    try {
      let params = {
        ApplyPagination: false,
        SearchText: searchText,
        FromDate: dates.length === 2 ? dates[0].format('MM-DD-YYYY') : '',
        ToDate: dates.length === 2 ? dates[1].format('MM-DD-YYYY') : '',
        FilterText: value === "Pending"
          ? "Pending"
          : value === "Approved"
            ? "Success"
            : value === "Declined"
              ? "Rejected"
              : value,
              ...sorter,
      };
      const { data } = await GetAllwalletinternalTransferApi(new URLSearchParams(params).toString());
      if (data?.success) {
        return { data: data?.data, removableColumns: ['userId', 'id'], fileName: 'Internal Transfer List' };
      } else {
        return { data: [], removableColumns: [] };
      }
    } catch (error) {
      return { data: [], removableColumns: [] };
    }
  };

  const handleDateChange = (dates) => {
    setDates(dates);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    getAllInternalTransfer();
  };

  const RequestHandlerApproved = async (value) => {
    if (!value) {
      if (!textAreaValue) {
        toast.error("Please enter a reason.");
        return;
      }
    }
    try {
      setIsReqLoading(true);
      let params = {

        comment: textAreaValue ? textAreaValue : "Accepted.",
        requestId: selectedInternalTransferid,
        status: 1,
        approvedReject: true,
      };
      const { data } = await InternalTransferRequestAcceptRejectApi(params);
      if (data?.success) {
        toast.success(data?.message);
        setIsOpenModal(false);
      }
      getAllInternalTransfer();
      setTextAreaValue("");
      setIsReqLoading(false);
    } catch (error) {
      toast.error(error?.response?.data?.error?.message);
      setIsReqLoading(false);
    }
    if (value) {
      setInternalTransferAccept(false);
    } else {
      setInternalTransferRejection(false);
    }
  };

  const RequestHandlerRejected = async (value) => {
    if (!value) {
      if (!textAreaValue) {
        toast.error("Please enter a reason.");
        return;
      }
    }
    try {
      setIsReqLoading(true);
      let params = {
        comment: textAreaValue ? textAreaValue : "Accepted.",
        requestId: selectedInternalTransferid,
        status: 4,
        approvedReject: false,
      };
      const { data } = await InternalTransferRequestAcceptRejectApi(params);
      if (data?.success) {
        toast.success(data?.message);
        setIsOpenModal(false);
      }
      getAllInternalTransfer();
      setTextAreaValue("");
      setIsReqLoading(false);
    } catch (error) {
      toast.error(error?.response?.data?.error?.message);
      setIsReqLoading(false);
    }
    if (value) {
      setInternalTransferAccept(false);
    } else {
      setInternalTransferRejection(false);
    }
  };


  return (
    <div>
      <div className="flex justify-between gap-5">
        <div className="mt-2">
          <Segmented
            options={["Pending", "Approved", "Declined"]}
            value={value}
            setValue={setValue}
          />
        </div>
        <div className="flex" >
          <div className="mr-3">
            <Input
              size="large"
              style={{ width: 220 }}
              placeholder="Search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              onPressEnter={() => setSearchText(search)}
              prefix={
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-5 h-5">
                  <path fill-rule="evenodd" d="M10.5 3.75a6.75 6.75 0 1 0 0 13.5 6.75 6.75 0 0 0 0-13.5ZM2.25 10.5a8.25 8.25 0 1 1 14.59 5.28l4.69 4.69a.75.75 0 1 1-1.06 1.06l-4.69-4.69A8.25 8.25 0 0 1 2.25 10.5Z" clip-rule="evenodd" />
                </svg>
              }
              suffix={search &&
                <svg xmlns="http://www.w3.org/2000/svg" onClick={() => { setSearchText(''); setSearch('') }} viewBox="0 0 24 24" fill="#bebebe" className="size-5 cursor-pointer duration-300 hover:fill-[#969595]">
                  <path fill-rule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm-1.72 6.97a.75.75 0 1 0-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 1 0 1.06 1.06L12 13.06l1.72 1.72a.75.75 0 1 0 1.06-1.06L13.06 12l1.72-1.72a.75.75 0 1 0-1.06-1.06L12 10.94l-1.72-1.72Z" clip-rule="evenodd" />
                </svg>
              }
            />
          </div>
          <div>
            <ExportHandler exportHandler={exportHandler} />
          </div>
        </div>
      </div>
      <div className="border-[2px] border-[var(--theme-light)] rounded-xl pt-4 lg:pt-6 w-full mt-5">
        <div className="flex justify-between gap-5 px-4 lg:px-6">
          <div>
            <p className="text-[#2F2B3D] text-xl font-semibold">Internal Transfers</p>
          </div>

          <form autoComplete="off" onSubmit={handleSubmit}>
            <div>
              <RangePicker className='border-light border-2 w-full mt-1 py-2' format='DD-MM-YYYY' onChange={handleDateChange} />
            </div>
          </form>
        </div>
        <div className="my-6">
          <TableWithSkeleton columns={columns} data={data} loading={isLoading} onChange={handleTableChange}/>
          <Pagination total={totalRecord} pageSize={pageSize} setPageSize={setPageSize} page={page} setPage={setPage} />
        </div>
      </div>


      <ModalComponent isOpen={isOpenModal} setIsOpen={setIsOpenModal} title="InternalTransfer Information" width={1300}>

        <div className='p-5'>
          <div className='grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 gap-10 '>
            <div>
              <p className='text-base font-normal '>First Name</p>
              <p className='text-xl primary font-semibold  mt-2'>{selectedInternalTransfer?.firstName || "--"}</p>
            </div>
            <div>
              <p className='text-base font-normal '>Last Name</p>
              <p className='text-xl primary font-semibold  mt-2'>{selectedInternalTransfer?.lastName || "--"}</p>
            </div>
            <div>
              <p className='text-base font-normal'> Wallet AccountNo</p>
              <p className='text-xl primary font-semibold  mt-2'>{selectedInternalTransfer?.walletAccountNo || "--"}</p>
            </div>
            <div>
              <p className='text-base font-normal '>Available Wallet Balance</p>
              <p className='text-xl primary font-semibold  mt-2'>$ {selectedInternalTransfer?.availableWalletBalance}</p>
            </div>

            <div>
              <p className='text-base font-normal '>Mobile No.</p>
              <p className='text-xl primary font-semibold  mt-2'>{selectedInternalTransfer?.phoneNo || "--"}</p>
            </div>
            <div>
              <p className='text-base font-normal '>Email</p>
              <p className='text-xl primary font-semibold  mt-2'>{selectedInternalTransfer?.email || "--"}</p>
            </div>
            <div>
              <p className='text-base font-normal '>Status</p>
              <p className='text-xl primary font-semibold  mt-2'>{selectedInternalTransfer?.status || '--'}</p>
            </div>
            <div>
              <p className='text-base font-normal '>Country Name</p>
              <p className='text-xl primary font-semibold  mt-2'>{selectedInternalTransfer?.countryName || "--"}</p>
            </div>

            <div>
              <p className='text-base font-normal '>Account No</p>
              <p className='text-xl primary font-semibold mt-2'> {selectedInternalTransfer?.accountNo || "--"}</p>
            </div>
            <div>
              <p className='text-base font-normal '>Amount</p>
              <p className='text-xl primary font-semibold mt-2'>$ {selectedInternalTransfer?.amount || "--"}</p>
            </div>
            <div>
              <p className='text-base font-normal '>Comment</p>
              <p className='text-xl primary font-semibold mt-2'> {selectedInternalTransfer?.remarks || "--"}</p>
            </div>
            <div>
              <p className='text-base font-normal '>Created Date</p>
              <p className='text-xl primary font-semibold mt-2'> {selectedInternalTransfer?.createdDate ? moment(selectedInternalTransfer?.createdDate).format("LLL") : "--" || "--"}</p>
            </div>
          </div>
          {!(selectedInternalTransfer?.status === "Approved" || selectedInternalTransfer?.status === "Rejected") && (
            <div className="flex justify-center items-center">
              <div className="mt-8">
                <button
                  onClick={() => { setselectedInternalTransferId(selectedInternalTransfer?.id); setInternalTransferAccept(true) }}
                  className="bg-[#2D9B6333] text-sm text-[#2D9B63] font-semibold px-4 py-2 rounded-lg uppercase hover:bg-[#2D9B63] hover:text-white duration-500"
                >
                  Accept
                </button>
                <button
                  onClick={() => { setselectedInternalTransferId(selectedInternalTransfer?.id); setInternalTransferRejection(true) }}
                  className="bg-[#F8222333] text-sm text-[#F82223] font-semibold px-4 py-2 rounded-lg uppercase hover:bg-[#F82223] hover:text-white duration-500 ml-4"
                >
                  Reject
                </button>
              </div>
            </div>
          )}

        </div>

      </ModalComponent>


      {/* ---- Accept-request ---- */}
      <ConfirmationModal
        isOpen={InternalTransferAccept}
        setIsOpen={setInternalTransferAccept}
        type="success"
        message="Are you sure you want to accept this request?"
        onConfirm={() => RequestHandlerApproved(true)}
        isLoading={isReqLoading}
        loadingLabel="Accepting request..."
      />

      {/* ---- Reject-request ---- */}
      <ConfirmationModal
        isOpen={InternalTransferRejection}
        setIsOpen={setInternalTransferRejection}
        message="Are you sure you want to reject this request?"
        onConfirm={() => RequestHandlerRejected(false)}
        isLoading={isReqLoading}
        loadingLabel="Rejecting request..."
        textArea={true}
        textAreaValue={textAreaValue}
        setTextAreaValue={setTextAreaValue}
        placeholder="Reason of rejection..."
      />


    </div>
  )
}
