import React, { useState, useEffect } from "react";
import { Input, Spin } from "antd";
import { useSearchParams } from "react-router-dom";
import columns from "../../columns/Kyc.js";
import Pagination from "../../components/global/pagination/Pagination.jsx";
import ConfirmationModal from "../../components/global/modal/ConfirmationModal.jsx";
import ModalComponent from "../../components/global/modal/ModalComponent.jsx";
import TableWithSkeleton from "../../components/global/table/TableWithSkeleton";
import { GetAllKycApi, GetAllKycByIdApi, KycRequestAcceptRejectApi } from "../../api/request/kyc.js";
import moment from "moment";
import toast from "react-hot-toast";
import Segmented from "../../components/segmented/Segmented.jsx";
import Eye from "../../assets/Eye.jsx";
import ExportHandler from "../../components/global/export/ExportHandler.jsx";

export default function Kyc() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecord, setTotalRecord] = useState(0);
  const [search, setSearch] = useState("");
  const [searchText, setSearchText] = useState("");
  const [selectedKycId, setSelectedKycId] = useState("");
  const [kycDetails, setKycDetails] = useState({});
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isKycDetailsLoading, setIsKycDetailsLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isReqLoading, setIsReqLoading] = useState(false);
  const [textAreaValue, setTextAreaValue] = useState("");
  const [kycRejection, setKycRejection] = useState(false);
  const [kycAccept, setKycAccept] = useState(false);
  const [data, setData] = useState([]);
  const [value, setValue] = useState("Pending");
  const [isOpenModalimg, setIsOpenModalimg] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [sorter, setSorter] = useState({ SortBy: 'requestDate', SortOrder: '' });

  const getAllKyc = async () => {
    try {
      let params = {
        PageNumber: page,
        PageSize: pageSize,
        FilterText:
          value === "Pending"
            ? "Pending"
            : value === "Approved"
              ? "Approved"
              : value === "Declined"
                ? "Rejected"
                : value,
        SearchText: searchText,
        ...sorter
      }
      setIsLoading(true);
      const { data } = await GetAllKycApi(new URLSearchParams(params)?.toString());
      if (data?.success) {
        setData(
          data?.data?.map((item) => {
            return {
              ...item,
              key: item?.userId,
              name: `${item?.firstName} ${item?.lastName}`,
              actionDate: (item?.actionDate ? moment(item?.actionDate).format('DD-MM-YYYY, hh:mm A') : '--'),
              actionBy: item?.actionBy?.trim() === "" ? '--' : item?.actionBy,
              requestDate: moment(item?.requestDate).format('DD-MM-YYYY, hh:mm A'),
              kycRequestDate: moment(item?.kycRequestDate).format("DD-MM-YYYY, hh:mm A"),
              kycApprovedDate: item?.kycApprovedDate ? moment(item?.kycApprovedDate).format("DD-MM-YYYY, hh:mm A") : null,
              actions: (<button type="button" onClick={() => { setSelectedKycId(item?.userId); setIsOpenModal(true); }}><Eye /></button>),
            };
          })
        );
        setTotalRecord(data?.totalRecords);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const getKycById = async () => {
    try {
      setIsKycDetailsLoading(true);
      const { data } = await GetAllKycByIdApi(selectedKycId);
      setKycDetails(data?.data);
      setIsKycDetailsLoading(false);
    } catch (error) {
      setIsKycDetailsLoading(false);
    }
  };
  const handleTableChange = (pagination, filters, sorter) => {
    if (sorter?.order) {
        setSorter({ SortBy: sorter?.field, SortOrder: sorter?.order });
    } else {
        setSorter({ SortBy: 'requestDate', SortOrder: '' });
    }
};

  useEffect(() => {
    if (selectedKycId) {
      getKycById();
    }
  }, [selectedKycId]);

  useEffect(() => {
    setSearchParams(`filter=${value?.toString()}`);
  }, [value]);

  useEffect(() => {
    if (searchParams?.get("filter")?.trim()) {
      setValue(searchParams?.get("filter")?.trim());
    }
  }, []);

  useEffect(() => {
    getAllKyc();
  }, [page, pageSize, searchText, value, sorter]);

  useEffect(() => {
    setKycDetails();
  }, [isOpenModal]);

  useEffect(() => {
    setPage(1);
  }, [searchText]);

  useEffect(() => {
    setSelectedKycId('');
  }, [isOpenModal])

  const exportHandler = async () => {
    try {
      let params = {
        ApplyPagination: false,
        FilterText:
          value === "Pending"
            ? "Pending"
            : value === "Approved"
              ? "Approved"
              : value === "Declined"
                ? "Rejected"
                : value,
        SearchText: searchText,
        ...sorter
      };
      const { data } = await GetAllKycApi(new URLSearchParams(params).toString());
      if (data?.success) {
        return { data: data?.data, removableColumns: ['userId'], fileName: 'KYC List' };
      } else {
        return { data: [], removableColumns: [] };
      }
    } catch (error) {
      return { data: [], removableColumns: [] };
    }
  };

  const kycRequestHandlerApproved = async (value) => {
    if (!value) {
      if (!textAreaValue) {
        toast.error("Please enter a reason.");
        return;
      }
    }
    try {
      setIsReqLoading(true);
      let params = {
        isRejected: false,
        isApproved: value,
        comment: textAreaValue ? textAreaValue : "Accepted.",
        userId: selectedRowKeys,
      };
      const { data } = await KycRequestAcceptRejectApi(params);
      if (data?.success) {
        toast.success(data?.message);
        setSelectedRowKeys([])
        setIsOpenModal(false);
      }
      getAllKyc();
      setTextAreaValue("");
      setIsReqLoading(false);
    } catch (error) {
      toast.error(error?.response?.data?.error?.message);
      setIsReqLoading(false);
    }
    if (value) {
      setKycAccept(false);
    } else {
      setKycRejection(false);
    }
  };

  const kycRequestHandlerRejected = async (value) => {
    if (!value) {
      if (!textAreaValue) {
        toast.error("Please enter a reason.");
        return;
      }
    }
    try {
      setIsReqLoading(true);
      let params = {
        isRejected: true,
        isApproved: false,
        comment: textAreaValue ? textAreaValue : "Accepted.",
        userId: selectedRowKeys,
      };
      const { data } = await KycRequestAcceptRejectApi(params);
      if (data?.success) {
        toast.success(data?.message);
        setSelectedRowKeys([])
        setIsOpenModal(false);
      }
      getAllKyc();
      setTextAreaValue("");
      setIsReqLoading(false);
    } catch (error) {
      toast.error(error?.response?.data?.error?.message);
      setIsReqLoading(false);
    }
    if (value) {
      setKycAccept(false);
    } else {
      setKycRejection(false);
    }
  };


  const openInNewTab = (url) => {
    if (url) {
      window.open(url, "_blank");
    }
  };


  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };



  return (
    <div>
      <div className="flex justify-between flex-wrap gap-5">
        <Segmented
          options={["Pending", "Approved", "Declined"]}
          value={value}
          setValue={setValue}
        />
        <div className="flex items-center">
          <div className="mr-3">
            <Input
              size="large"
              style={{ width: 220 }}
              placeholder="Search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              onPressEnter={() => setSearchText(search)}
              prefix={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  class="w-5 h-5"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10.5 3.75a6.75 6.75 0 1 0 0 13.5 6.75 6.75 0 0 0 0-13.5ZM2.25 10.5a8.25 8.25 0 1 1 14.59 5.28l4.69 4.69a.75.75 0 1 1-1.06 1.06l-4.69-4.69A8.25 8.25 0 0 1 2.25 10.5Z"
                    clip-rule="evenodd"
                  />
                </svg>
              }
              suffix={
                search && (
                  <svg xmlns="http://www.w3.org/2000/svg" onClick={() => { setSearchText(""); setSearch(""); }}
                    viewBox="0 0 24 24" fill="#bebebe" className="size-5 cursor-pointer duration-300 hover:fill-[#969595]">
                    <path fill-rule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm-1.72 6.97a.75.75 0 1 0-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 1 0 1.06 1.06L12 13.06l1.72 1.72a.75.75 0 1 0 1.06-1.06L13.06 12l1.72-1.72a.75.75 0 1 0-1.06-1.06L12 10.94l-1.72-1.72Z" clip-rule="evenodd" />
                  </svg>
                )
              }
            />
          </div>
          <ExportHandler exportHandler={exportHandler} />
        </div>
      </div>

      <div className="border-[2px] border-[var(--theme-light)] rounded-xl pt-4 lg:pt-6 w-full mt-5">
        <div className="flex justify-between items-center flex-wrap gap-5 px-4 lg:px-6">
          <p className="text-[#2F2B3D] text-xl font-semibold">{value}</p>
          {(value === "Pending" && selectedRowKeys.length > 0) && (
            <div className="flex justify-center items-center">
              <div className="mt-1">
                <button
                  onClick={() => {
                    setKycAccept(true);
                  }}
                  className="bg-[#2D9B6333] text-sm text-[#2D9B63] font-semibold px-4 py-2 rounded-lg uppercase hover:bg-[#2D9B63] hover:text-white duration-500"
                >
                  Accept
                </button>
                <button
                  onClick={() => {
                    setKycRejection(true);
                  }}
                  className="bg-[#F8222333] text-sm text-[#F82223] font-semibold px-4 py-2 rounded-lg uppercase hover:bg-[#F82223] hover:text-white duration-500 ml-4"
                >
                  Reject
                </button>
              </div>
            </div>
          )}
        </div>
        <div className="my-6">
          <TableWithSkeleton columns={columns} rowSelection={value === "Pending" ? rowSelection : null} data={data} loading={isLoading} onChange={handleTableChange}/>
          <Pagination total={totalRecord} page={page} setPage={setPage} pageSize={pageSize} setPageSize={setPageSize} />
        </div>
      </div>

      {/* ---- Accept-request ---- */}
      <ConfirmationModal
        isOpen={kycAccept}
        setIsOpen={setKycAccept}
        type="success"
        message="Are you sure you want to accept this request?"
        onConfirm={() => kycRequestHandlerApproved(true)}
        isLoading={isReqLoading}
        loadingLabel="Accepting request..."
      />

      {/* ---- Reject-request ---- */}
      <ConfirmationModal
        isOpen={kycRejection}
        setIsOpen={setKycRejection}
        message="Are you sure you want to reject this request?"
        onConfirm={() => kycRequestHandlerRejected(false)}
        isLoading={isReqLoading}
        loadingLabel="Rejecting request..."
        textArea={true}
        textAreaValue={textAreaValue}
        setTextAreaValue={setTextAreaValue}
        placeholder="Reason of rejection..."
      />

      {/* ---- KYC Information ---- */}
      <ModalComponent isOpen={isOpenModal} setIsOpen={setIsOpenModal} title="KYC Information" width={1300}>
        {
          isKycDetailsLoading ? (
            <div className="flex justify-center flex-col my-[10%]">
              <Spin size="large" />
              <p className="primary text-center mt-2">Loading...</p>
            </div>
          ) : (
            <div className='p-5'>
              <div className='grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 gap-10 '>
                <div>
                  <p className='text-base font-normal '>First Name</p>
                  <p className='text-xl primary font-semibold  mt-2'>{kycDetails?.firstName || "--"}</p>
                </div>
                <div>
                  <p className='text-base font-normal '>Last Name</p>
                  <p className='text-xl primary font-semibold  mt-2'>{kycDetails?.lastName || "--"}</p>
                </div>
                <div>
                  <p className='text-base font-normal '> User Type</p>
                  <p className='text-xl primary font-semibold  mt-2'>{kycDetails?.userType || "--"}</p>
                </div>
                <div>
                  <p className='text-base font-normal '>Created Time</p>
                  <p className='text-xl primary font-semibold  mt-2'> {kycDetails?.requestDate ? moment(kycDetails?.requestDate).format("LLL") : "--"}</p>
                </div>
                {kycDetails?.kycStatus === "Approved" ? <div>
                  <p className='text-base font-normal '>Approved Time</p>
                  <p className='text-xl primary font-semibold  mt-2'>{kycDetails?.actionDate ? moment(kycDetails?.actionDate).format("LLL") : "--"}</p>
                </div> : ""}
                {kycDetails?.kycStatus === "Rejected" ? <div>
                  <p className='text-base font-normal '>Declined Time</p>
                  <p className='text-xl primary font-semibold  mt-2'>{kycDetails?.actionDate ? moment(kycDetails?.actionDate).format("LLL") : "--"}</p>
                </div> : ""}
                <div>
                  <p className='text-base font-normal '>Mobile No.</p>
                  <p className='text-xl primary font-semibold  mt-2'>{kycDetails?.phoneNo || "--"}</p>
                </div>
                <div>
                  <p className='text-base font-normal '>Email</p>
                  <p className='text-xl primary font-semibold  mt-2'>{kycDetails?.email || "--"}</p>
                </div>
                <div>
                  <p className='text-base font-normal '>Kyc Status</p>
                  <p className='text-xl primary font-semibold  mt-2'>{kycDetails?.kycStatus || '--'}</p>
                </div>
                <div>
                  <p className='text-base font-normal '>Country Name</p>
                  <p className='text-xl primary font-semibold  mt-2'>{kycDetails?.countryName || "--"}</p>
                </div>
                {
                  kycDetails?.kycType == 'manual' &&
                  <div>
                    <p className="text-[#1E232CC7] text-lg">Document</p>

                    <div className="flex items-center cursor-pointer" onClick={() => setIsOpenModalimg(true)}>
                      <p className="primary text-lg font-semibold mt-2 underline mr-2">View Document</p>
                      {/* <Download /> */}
                    </div>
                  </div>
                }
                <div>
                  <p className='text-base font-normal '>Document Name</p>
                  <p className='text-xl primary font-semibold mt-2'> {kycDetails?.documentType || "--"}</p>
                </div>


              </div>
              {!(kycDetails?.kycStatus === "Approved" || kycDetails?.kycStatus === "Rejected") && (
                <div className="flex justify-center items-center">
                  <div className="mt-8">
                    <button
                      onClick={() => { setSelectedRowKeys([kycDetails?.userId]); setKycAccept(true) }}
                      className="bg-[#2D9B6333] text-sm text-[#2D9B63] font-semibold px-4 py-2 rounded-lg uppercase hover:bg-[#2D9B63] hover:text-white duration-500"
                    >
                      Accept
                    </button>
                    <button
                      onClick={() => { setSelectedRowKeys([kycDetails?.userId]); setKycRejection(true) }}
                      className="bg-[#F8222333] text-sm text-[#F82223] font-semibold px-4 py-2 rounded-lg uppercase hover:bg-[#F82223] hover:text-white duration-500 ml-4"
                    >
                      Reject
                    </button>
                  </div>
                </div>
              )}
              {kycDetails?.kycStatus === "Rejected" ? <div className="mt-4">
                <p className='text-base font-normal '>Comment</p>
                <p className='text-xl primary font-semibold  mt-2'>{kycDetails?.comment || "--"}</p>
              </div> : ""}
            </div>
          )
        }
      </ModalComponent>

      {/* View Document */}
      <ModalComponent isOpen={isOpenModalimg} setIsOpen={setIsOpenModalimg} title="View Document" width={1000}>
        {
          isKycDetailsLoading ? (
            <div className="flex justify-center flex-col my-[10%]">
              <Spin size="large" />
              <p className="primary text-center mt-2">Loading...</p>
            </div>
          ) : (
            <div>
              {kycDetails?.fileUrlPath && kycDetails.fileUrlPath.length > 0 ? (
                <div className='p-5'>
                  <div className='grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 gap-10'>
                    {kycDetails.fileUrlPath.map((url, index) => (
                      <div key={index}>
                        <div className="border border-gray-300 rounded-lg">
                          {url.endsWith('.pdf') ? (
                            <iframe
                              src={url}
                              title={`KYC Document ${index + 1}`}
                              className="w-full h-64 rounded-lg cursor-pointer"
                              onClick={() => openInNewTab(url)}
                            />
                          ) : (
                            <img
                              src={url}
                              alt={`KYC Document ${index + 1}`}
                              className="w-full h-auto rounded-lg cursor-pointer"
                              onClick={() => openInNewTab(url)}
                            />
                          )}
                        </div>
                        {/* <button type="button" className="flex items-center gap-2 primary mx-auto mt-2" onClick={() => downloadImage(url)}>
                          <Download />
                          Download
                        </button> */}
                      </div>
                    ))}
                  </div>
                </div>
              ) : (
                <p>No documents available</p>
              )}
            </div>

          )
        }
      </ModalComponent>
    </div>
  );
}
