import React, { useState, useEffect } from "react";
import columns from "../../../columns/PaymentGatway";
import ConfirmationModal from "../../../components/global/modal/ConfirmationModal.jsx";
import Pagination from "../../../components/global/pagination/Pagination.jsx";
import { GetAllPaymentGatwaysApi, UpdatePaymentGatwayStatusApi, UpdategetwayApi,GetAllCountryApi } from "../../../api/request/settings/paymentGatway.js";
import TableWithSkeleton from "../../../components/global/table/TableWithSkeleton";
import toast from "react-hot-toast";
import Edit from "../../../assets/Edit.jsx";
import ModalComponent from "../../../components/global/modal/ModalComponent.jsx";
import { Select, Form, Switch } from "antd";
import LoadableButton from "../../../components/buttons/LoadableButton.jsx";

export default function PaymentGatway() {
  const [form] = Form.useForm();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecord, setTotalRecord] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [selectedPaymentGatway, setSelectedPaymentGatway] = useState(null);
  const [isStatusConfirmOpen, setIsStatusConfirmOpen] = useState(false);
  const [isStatusConfirmLoading, setIsStatusConfirmLoading] = useState(false);
  const [selectedid, setSelectedid] = useState(null);
  const [isOpenEditModal, setIsOpenEditModal] = useState(false);
  const [initialValues, setInitialValues] = useState({ demoAccount: "",});
  const [isEditTraderLoading, setIsEditTraderLoading] = useState(false);
  const [countries, setCountries] = useState([]);

  const statusHandler = (value) => {
    setSelectedPaymentGatway(value);
    setIsStatusConfirmOpen(true);
  }

  const getContriens = async () => {
    try {
      const { data } = await GetAllCountryApi();
      if (data?.success) {
        setCountries(data?.data?.map((item) => ({ ...item, value: item?.id, label: item?.countryName })));
      }
    } catch (error) { }
  }

  useEffect(() => {
    getContriens();
  }, []);

  const getPaymentGatways = async () => {
    try {
      setIsLoading(true);
      let params = {
        PageNumber: page,
        PageSize: pageSize,
      }
      const { data } = await GetAllPaymentGatwaysApi(new URLSearchParams(params).toString());
      if (data?.success) {
        setData(
          data?.data?.map((item) => {
            return {
              ...item,
              // fees:`$ ${item?.fees}`,
              status: <Switch onChange={() => statusHandler(item)} checkedChildren="Active" unCheckedChildren="Inactive" checked={item?.isActive} />,
              action: (<>
                <button type="button" onClick={() => { setSelectedid(item?.id); setIsOpenEditModal(true); }}><Edit /></button>
              </>
              ),
            }
          })
        );
        setTotalRecord(data?.totalRecords);
      }
      setIsLoading(false);
    } catch (error) {
      setData([]);
      setIsLoading(false);
    }
  }

  const statusChangeConfirm = async () => {
    try {
      setIsStatusConfirmLoading(true);

      let params = {
        Status: !selectedPaymentGatway?.isActive
      }

      const { data } = await UpdatePaymentGatwayStatusApi(selectedPaymentGatway?.id, new URLSearchParams(params).toString());
      if (data?.success) {
        getPaymentGatways();
        toast.success(data?.message);
      }

      setIsStatusConfirmLoading(false);
      setIsStatusConfirmOpen(false);
    } catch (error) {
      setIsStatusConfirmLoading(false);
    }
  }

  useEffect(() => {
    getPaymentGatways();
  }, [page, pageSize]);

  const handleFieldsChange = (_, allFields) => {
    const isChanged = allFields.some(field => field.touched);
  };

  const handleEditSubmit = async (value) => {
    try {
     
      let apiParams = {
        ...value,
      };
      setIsEditTraderLoading(true);
      const { data } = await UpdategetwayApi(new URLSearchParams(apiParams).toString(),selectedid);
      if (data?.success) {
        toast.success(data?.message);
        form.resetFields();
        setIsEditTraderLoading(false);
        setIsOpenEditModal(false);
        getPaymentGatways();
      }
    } catch (error) {
      toast.error(error?.response?.data?.error?.message);
      setIsEditTraderLoading(false);
    }
  };

  const filterOption = (input, option) => {
    return (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
  }

  return (
    <div>
      <div className="border-[2px] border-light rounded-[30px] pt-4 lg:pt-6 w-full">
        <div className="px-4 lg:px-6">
          <p className="text-[#2F2B3D] text-xl font-semibold">All Payment Gatway</p>
        </div>
        <div className="my-6">
          <TableWithSkeleton columns={columns} data={data} loading={isLoading} />
          <Pagination total={totalRecord} page={page} setPage={setPage} pageSize={pageSize} setPageSize={setPageSize} />
        </div>
      </div>

      {/* Payment gatway status change confirmation modal */}
      <ConfirmationModal
        isOpen={isStatusConfirmOpen}
        setIsOpen={setIsStatusConfirmOpen}
        message='Are you sure to change the payment gatway status?'
        onConfirm={statusChangeConfirm}
        isLoading={isStatusConfirmLoading}
        loadingLabel='Changing status...'
      />
      {/* Update user modal */}
      <ModalComponent isOpen={isOpenEditModal} setIsOpen={setIsOpenEditModal} title="Update" width={500}>
        <Form className="w-full" autoComplete="off" form={form} initialValues={initialValues} onFinish={handleEditSubmit} onFieldsChange={handleFieldsChange}>
          
          <div className="ant-multi-select-selector ant-select-selector-white">
            <p className="text-[#585F70] font-semibold mb-[2px]">Select Your Country</p>
            <Form.Item name="ContryId" rules={[{ required: true, message: 'Please select a country.' }]}>
              <Select showSearch mode="multiple" filterOption={filterOption} options={countries} className="w-full mt-1" placeholder='Select a country' />
            </Form.Item>
          </div>
          <div className="flex justify-end mt-8">
            <LoadableButton
              className="bg-primary text-sm text-white font-semibold rounded-lg px-12 py-2 uppercase themeHover  duration-500"
              type="Submit"
              lable="Update"
              isLoading={isEditTraderLoading}
              loadingLable="Updating rules..."
            />
          </div>
        </Form>
      </ModalComponent>
    </div>
  );
}
