import React, { useState, useEffect } from "react";
import { Input, DatePicker } from "antd";
import Pagination from "../../../components/global/pagination/Pagination.jsx";
import columns from "../../../columns/wallet/Deposit.js";
import TableWithSkeleton from "../../../components/global/table/TableWithSkeleton.jsx";
import { GetAllwalletDepositApi } from "../../../api/request/wallet/deposit.js";
import moment from "moment";
import ExportHandler from "../../../components/global/export/ExportHandler.jsx";
const { RangePicker } = DatePicker;
export default function Deposit() {

  const [pageSize, setPageSize] = useState(10);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [searchText, setSearchText] = useState('');
  const [totalRecord, setTotalRecord] = useState(0);
  const [search, setSearch] = useState('');
  const [dates, setDates] = useState([]);

  const getAllDeposit = async () => {
    try {
      let params = {
        PageNumber: page,
        PageSize: pageSize,
        SearchText: searchText,
        FromDate: dates.length === 2 ? dates[0].format('MM-DD-YYYY') : '',
        ToDate: dates.length === 2 ? dates[1].format('MM-DD-YYYY') : '',
      }
      setIsLoading(true);
      const { data } = await GetAllwalletDepositApi(new URLSearchParams(params).toString());
      if (data?.success) {
        setData(
          data.data?.map((item) => {
            return {
              ...item,
              createdDate: moment(item?.createdDate).format('DD-MM-YYYY, hh:mm A'),
              amount:`$ ${item?.amount}`,
              status: <p className='uppercase font-bold text-[#2D9B63] text-sm'>Success</p>,
            };
          })
        );
        setTotalRecord(data?.totalRecords);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  }

  const handleDateChange = (dates) => {
    setDates(dates);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    getAllDeposit();
  };

  useEffect(() => {
    getAllDeposit();
  }, [page, pageSize, searchText, dates]);

  const exportHandler = async () => {
    try {
      let params = {
        ApplyPagination: false,
        SearchText: searchText,
        FromDate: dates.length === 2 ? dates[0].format('MM-DD-YYYY') : '',
        ToDate: dates.length === 2 ? dates[1].format('MM-DD-YYYY') : '',
      };
      const { data } = await GetAllwalletDepositApi(new URLSearchParams(params).toString());
      if (data?.success) {
        return { data: data?.data, removableColumns: ['userId'], fileName: 'Deposit List' };
      } else {
        return { data: [], removableColumns: [] };
      }
    } catch (error) {
      return { data: [], removableColumns: [] };
    }
  };

  return (
    <div>
      <div className="flex justify-end flex-wrap gap-5">
        <div className="flex items-center">
          <div className="mr-3">
            <Input
              size="large"
              style={{ width: 220 }}
              placeholder="Search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              onPressEnter={() => setSearchText(search)}
              prefix={
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-5 h-5">
                  <path fill-rule="evenodd" d="M10.5 3.75a6.75 6.75 0 1 0 0 13.5 6.75 6.75 0 0 0 0-13.5ZM2.25 10.5a8.25 8.25 0 1 1 14.59 5.28l4.69 4.69a.75.75 0 1 1-1.06 1.06l-4.69-4.69A8.25 8.25 0 0 1 2.25 10.5Z" clip-rule="evenodd" />
                </svg>
              }
              suffix={search &&
                <svg xmlns="http://www.w3.org/2000/svg" onClick={() => { setSearchText(''); setSearch('') }} viewBox="0 0 24 24" fill="#bebebe" className="size-5 cursor-pointer duration-300 hover:fill-[#969595]">
                  <path fill-rule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm-1.72 6.97a.75.75 0 1 0-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 1 0 1.06 1.06L12 13.06l1.72 1.72a.75.75 0 1 0 1.06-1.06L13.06 12l1.72-1.72a.75.75 0 1 0-1.06-1.06L12 10.94l-1.72-1.72Z" clip-rule="evenodd" />
                </svg>
              }

            />
          </div>
          <div>
            <ExportHandler exportHandler={exportHandler} />
          </div>
        </div>
      </div>

      <div className="border-[2px] border-[var(--theme-light)] rounded-xl pt-4 lg:pt-6 w-full mt-5">
        <div className="flex px-4 lg:px-6">
          <div><p className="text-[#2F2B3D] text-xl mt-1 font-semibold">Deposits</p></div>
          <form autoComplete="off" onSubmit={handleSubmit}>
            <div className="ml-3">
              <RangePicker className='border-2 w-full py-2 border-[#d9d9d9]' format='DD-MM-YYYY' onChange={handleDateChange} />
            </div>
          </form>
        </div>
        <div className="my-6">
          <TableWithSkeleton columns={columns} data={data} loading={isLoading} />
          <Pagination total={totalRecord} pageSize={pageSize} setPageSize={setPageSize} page={page} setPage={setPage} />
        </div>
      </div>
    </div>
  )
}
