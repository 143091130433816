import Account from "../../../../assets/Account";
import AccountType from "../../../../assets/AccountType";
import Banner from "../../../../assets/Banner";
import Client from "../../../../assets/Client";
import Dashboard from "../../../../assets/Dashboard";
import EmailTemplate from "../../../../assets/EmailTemplate";
import IbApproved from "../../../../assets/IbApproved";
import Kyc from "../../../../assets/Kyc";
import Task from "../../../../assets/Task";
import Tutorial from "../../../../assets/Tutorial";
import Disc from "../../../../assets/common/Disc";
import IbCommission from "../../../../assets/ibCommision/IbCommission";
import Support from "../../../../assets/support/Support";
import SystemSetting from "../../../../assets/systemSetting/SystemSetting";
import Wallet from "../../../../assets/wallet/Wallet";
import Promotion from "../../../../assets/Promotion.jsx";
import Reports from "../../../../assets/Reports.jsx";
import Sales from "../../../../assets/Sales.jsx";
import CashDeposit from "../../../../assets/CashDeposit.jsx";


export const SidebarData = [
    {
        title: 'Dashboard',
        icon: <Dashboard />,
        url: '/dashboard',
        activeUrl: ['/dashboard']
    },

    {
        title: 'Inbox',
        icon: <EmailTemplate />,
        url: '/inbox',
        activeUrl: ['/inbox']
    },
    {
        title: 'Client',
        icon: <Client />,
        url: '/client',
        activeUrl: ['/client', '/client_details', '/account_details', '/parent_tree', '/ib_tree', '/mt5_account', , '/deposit', '/deposit_usdt', '/withdraw', '/logs', '/live_account_details']
    },
    {
        title: 'Cash Deposit',
        icon: <CashDeposit />,
        activeUrl: ['/bank_details', '/deposit_request', '/usd_conversion'],
        children: [
            {
                title: 'Bank Details',
                activeUrl: ['/bank_details'],
                icon: <Disc />,
                url: '/bank_details',
            },
            {
                title: 'Deposit Request',
                activeUrl: ['/deposit_request'],
                icon: <Disc />,
                url: '/deposit_request',
            },
            {
                title: 'USD Conversion',
                activeUrl: ['/usd_conversion'],
                icon: <Disc />,
                url: '/usd_conversion',
            },
        ],
    },
    {
        title: 'KYC',
        icon: <Kyc />,
        url: '/kyc',
        activeUrl: ['/kyc']
    },
    {
        title: 'Wallet',
        icon: <Wallet />,
        activeUrl: ['/wallet_withdraw', '/wallet_deposit', '/wallet_internal_transfer'],
        children: [
            {
                title: 'Withdraw',
                icon: <Disc />,
                url: '/wallet_withdraw',
                activeUrl: ['/wallet_withdraw'],
            },
            {
                title: 'Deposit',
                icon: <Disc />,
                url: '/wallet_deposit',
                activeUrl: ['/wallet_deposit'],
            },
            {
                title: 'Internal Transfer',
                icon: <Disc />,
                url: '/wallet_internal_transfer',
                activeUrl: ['/wallet_internal_transfer'],
            },
        ],
    },
    {
        title: 'Commission',
        icon: <IbCommission />,
        activeUrl: ['/ib_commission_generate', '/ib_commission_withdraw', '/ib_commission', '/ib_commission_reports'],
        children: [
            {
                title: 'Generate',
                url: '/ib_commission_generate',
                activeUrl: ['/ib_commission_generate', '/ib_commission'],
                icon: <Disc />,
            },
            {
                title: 'Withdraw',
                url: '/ib_commission_withdraw',
                activeUrl: ['/ib_commission_withdraw'],
                icon: <Disc />,
            },
            {
                title: 'Reports',
                url: '/ib_commission_reports',
                activeUrl: ['/ib_commission_reports'],
                icon: <Disc />,
            },
            // {
            //     title: 'Rollback',
            //     url: '/ib_commission_rollback',
            //     activeUrl: ['/ib_commission_rollback'],
            //     icon: <Disc />,
            // },
        ],
    },
    {
        title: 'IB Manager',
        icon: <IbApproved />,
        url: '/ib_manager',
        activeUrl: ['/ib_manager', '/ib_details']
    },

    {
        title: 'Accounts',
        icon: <Account />,
        url: '/accounts',
        activeUrl: ['/accounts']
    },
    // {
    //     title: 'Task',
    //     icon: <Task />,
    //     url: '/task',
    //     activeUrl: ['/task']
    // },
    {
        title: 'Sales',
        icon: <Sales />,
        activeUrl: ['/sales_dashboard', '/source', '/leads'],
        children: [
            {
                title: 'Dashboard',
                icon: <Disc />,
                url: '/sales_dashboard',
                activeUrl: ['/sales_dashboard'],
            },
            {
                title: 'Source',
                icon: <Disc />,
                url: '/source',
                activeUrl: ['/source'],
            },
            {
                title: 'Leads',
                icon: <Disc />,
                url: '/leads',
                activeUrl: ['/leads'],
            },
        ],
    },
    {
        title: 'Reports',
        icon: <Reports />,
        activeUrl: ['/wallet_withdraw', '/wallet_deposit', '/wallet_internal_transfer', '/ib_commission_generate','/commission_data'],
        children: [
            {
                title: 'Withdraw',
                icon: <Disc />,
                url: '/wallet_withdraw',
                activeUrl: ['/wallet_withdraw'],
            },
            {
                title: 'Deposit',
                icon: <Disc />,
                url: '/wallet_deposit',
                activeUrl: ['/wallet_deposit'],
            },
            {
                title: 'Internal Transfer',
                icon: <Disc />,
                url: '/wallet_internal_transfer',
                activeUrl: ['/wallet_internal_transfer'],
            },
            {
                title: 'IB Commission',
                icon: <Disc />,
                url: '/ib_commission_generate',
                activeUrl: ['/ib_commission_generate'],
            },
            {
                title: 'Threshold',
                icon: <Disc />,
                url: '/thres_hold_reports',
                activeUrl: ['/thres_hold_reports'],
            },

        ],
    },
    {
        title: 'Banner',
        icon: <Banner />,
        url: '/banner',
        activeUrl: ['/banner']
    },
    {
        title: 'Promotion Kit',
        icon: <Promotion />,
        url: '/promotion_kit',
        activeUrl: ['/promotion_kit']
    },
    {
        title: 'Sub Admins',
        icon: <Client />,
        url: '/sub_admin',
        activeUrl: ['/sub_admin']
    },
    {
        title: 'Support',
        icon: <Support />,
        activeUrl: ['/ticket', '/view_ticket', '/faq'],
        children: [
            {
                title: 'Ticket',
                activeUrl: ['/ticket', '/view_ticket'],
                icon: <Disc />,
                url: '/ticket',
            },
            {
                title: 'FAQ',
                activeUrl: ['/faq'],
                icon: <Disc />,
                url: '/faq',
            },
        ],
    },
    {
        title: 'Tutorial',
        icon: <Tutorial />,
        url: '/tutorial',
        activeUrl: ['/tutorial']
    },
    {
        title: 'Email Template',
        icon: <EmailTemplate />,
        url: '/email_template',
        activeUrl: ['/email_template', '/add_email_template', '/edit_email_template']
    },
    {
        title: 'Account Type',
        icon: <AccountType />,
        url: '/account_Type',
        activeUrl: ['/account_Type']
    },
    {
        title: 'Log',
        icon: <Task />,
        url: '/log',
        activeUrl: ['/log']
    },
    {
        title: 'Settings',
        icon: <SystemSetting />,
        activeUrl: ['/notifications', '/kyc_Rules', '/roles', '/rules', '/payment_Gateway', '/assign_Rules', 'payment_Gateway', 'kyc_Rules', 'group_Symbols', '/teams'],
        children: [
            {
                title: 'Notifications',
                icon: <Disc />,
                url: '/notifications',
                activeUrl: ['/notifications'],
            },
            {
                title: 'KYC Rules',
                icon: <Disc />,
                url: '/kyc_Rules',
                activeUrl: ['/kyc_Rules'],
            },
            {
                title: 'Rules',
                icon: <Disc />,
                url: '/rules',
                activeUrl: ['/rules'],
            },
            {
                title: 'PSP',
                url: '/payment_Gateway',
                activeUrl: ['/payment_Gateway'],
                icon: <Disc />,
            },
            {
                title: 'Symbol',
                url: '/symbol',
                activeUrl: ['/symbol'],
                icon: <Disc />,
            },
            {
                title: 'Threshold',
                url: '/thres_hold',
                activeUrl: ['/thres_hold'],
                icon: <Disc />,
            },
            // {
            //     title: 'Roles',
            //     url: '/roles',
            //     activeUrl: ['/roles'],
            //     icon: <Disc />,
            // },
            // {
            //     title: 'Manage Team',
            //     url: '/teams',
            //     activeUrl: ['/teams'],
            //     icon: <Disc />,
            // },
        ],
    }
]