import React, { useState, useEffect } from "react";
import Pagination from "../../components/global/pagination/Pagination.jsx";
import columns from "../../columns/MT5Account/MT5PendingAccounts.js";
import TableWithSkeleton from "../../components/global/table/TableWithSkeleton.jsx";
import ConfirmationModal from "../../components/global/modal/ConfirmationModal.jsx";
import { GetAllAccountApi, MT5RequestAcceptRejectApi } from "../../api/request/account.js";
import toast from "react-hot-toast";
import moment from "moment";

export default function Accounts({ dates }) {

    const [isLoading, setIsLoading] = useState(false);
    const [pageSize, setPageSize] = useState(10);
    const [page, setPage] = useState(1);
    const [filter, setFilter] = useState([]);
    const [totalRecord, setTotalRecord] = useState(0);
    const [data, setData] = useState([]);
    const [mt5Accept, setMT5Accept] = useState(false);
    const [textAreaValue, setTextAreaValue] = useState('');
    const [mt5Rejection, setMT5Rejection] = useState(false);
    const [isReqLoading, setIsReqLoading] = useState(false);
    const [selectedmt5, setSelectedMT5] = useState({ userId: null, mT5Id: null });
    const [sorter, setSorter] = useState({ SortBy: 'createdDate', SortOrder: '' });


    const setSelectedMT5Id = (userId, mT5Id) => {
        setSelectedMT5({ userId, mT5Id });
    };


    const getAllmt5 = async () => {
        try {
            let params = {
                PageNumber: page,
                PageSize: pageSize,
                SearchText: dates,
                FilterText: "Pending",
                ...sorter
            }
            setIsLoading(true);
            const { data } = await GetAllAccountApi(new URLSearchParams(params).toString());
            if (data?.success) {
                setData(
                    data?.data?.map((item) => {
                        const { userId, mT5Id } = item;

                        return {
                            ...item,
                            balance:`$ ${item?.balance}`,
                            createdDate: (item?.createdDate ? moment(item?.createdDate).format("DD-MM-YYYY, hh:mm A") : null),

                            actions: (
                                <div className="flex">
                                    <button
                                        className="mr-3 ml-3"
                                        onClick={() => {
                                            setSelectedMT5Id(userId, mT5Id);
                                            setMT5Accept(true);
                                        }}
                                    >
                                        <img src="assets/icons/accept_icon.svg" alt="icon" width={27} />
                                    </button>
                                    <button onClick={() => {
                                        setSelectedMT5Id(userId, mT5Id);
                                        setMT5Rejection(true)
                                    }}><img src="assets/icons/delete_icon.svg" alt="icon" width={27} /></button>
                                </div>
                            ),
                        };
                    })
                );
                setTotalRecord(data?.totalRecords);
            }
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
        }
    }
    const handleTableChange = (pagination, filters, sorter) => {
        if (sorter?.order) {
            setSorter({ SortBy: sorter?.field, SortOrder: sorter?.order });
        } else {
            setSorter({ SortBy: 'createdDate', SortOrder: '' });
        }
    };

    useEffect(() => {
        getAllmt5();
    }, [page, pageSize, dates, filter, sorter]);

    const MT5RequestHandlerApproved = async () => {
        try {
            setIsReqLoading(true);
            let params = {
                isRejected: false,
                isApproved: true,
                comment: textAreaValue ? textAreaValue : 'Accepted.',
                userId: selectedmt5?.userId,
                mT5Id: selectedmt5?.mT5Id

            }
            const { data } = await MT5RequestAcceptRejectApi(params);
            if (data?.success) {
                toast.success(data?.message);
                // setIsOpenModal(false);
                getAllmt5();
            }
            setIsReqLoading(false);
        } catch (error) {
            toast.error(error?.response?.data?.error?.message);
            setIsReqLoading(false);
        }
        setMT5Accept(false);
    }

    const MT5RequestHandlerRejected = async () => {
        if (!textAreaValue) {
            toast.error('Please enter a reason.');
            return;
        }
        try {
            setIsReqLoading(true);
            let params = {
                isRejected: true,
                isApproved: false,
                comment: textAreaValue ? textAreaValue : 'Accepted.',
                userId: selectedmt5?.userId,
                mT5Id: selectedmt5?.mT5Id

            }
            const { data } = await MT5RequestAcceptRejectApi(params);
            if (data?.success) {
                toast.success(data?.message);
                // setIsOpenModal(false);
                getAllmt5();
                setTextAreaValue('');
            }
            setIsReqLoading(false);
        } catch (error) {
            toast.error(error?.response?.data?.error?.message);
            setIsReqLoading(false);
        }
        setMT5Rejection(false);
    }

    return (
        <div>

            <div className="border-[2px] border-[var(--theme-light)] rounded-xl pt-4 lg:pt-6 w-full mt-5">
                <div className="px-4 lg:px-6">
                    <p className="text-[#2F2B3D] text-xl font-semibold">
                        Pending Request
                    </p>
                </div>
                <div className="my-6">
                    <TableWithSkeleton columns={columns} data={data} loading={isLoading} onChange={handleTableChange}/>
                    <Pagination total={totalRecord} page={page} setPage={setPage} pageSize={pageSize} setPageSize={setPageSize} />
                </div>
            </div>

            {/* ---- Accept-request ---- */}


            <ConfirmationModal
                isOpen={mt5Accept}
                setIsOpen={setMT5Accept}
                type='success'
                message='Are you sure you want to accept this request?'
                onConfirm={() => MT5RequestHandlerApproved(true)}
                isLoading={isReqLoading}
                loadingLabel='Accepting request...'
            />

            {/* ---- Reject-request ---- */}


            <ConfirmationModal
                isOpen={mt5Rejection}
                setIsOpen={setMT5Rejection}
                message='Are you sure you want to reject this request?'
                onConfirm={() => MT5RequestHandlerRejected(false)}
                isLoading={isReqLoading}
                loadingLabel='Rejecting request...'
                textArea={true}
                textAreaValue={textAreaValue}
                setTextAreaValue={setTextAreaValue}
                placeholder='Reason of rejection...'
            />
        </div>
    )
}