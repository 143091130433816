import React, { useEffect, useState } from 'react'
import columns from '../../../columns/ibApproved/ibDetails.js';
import MyPagination from '../../../components/global/pagination/Pagination.jsx';
import TableWithSkeleton from '../../../components/global/table/TableWithSkeleton.jsx';
import { GetCommissionAssignTreeApi } from '../../../api/request/ibapproved.js';
import { useSelector } from 'react-redux';
import { Input, Spin } from 'antd';
import { useParams } from 'react-router-dom';

export default function IbDetails() {

    const { id } = useParams();
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalRecord, setTotalRecord] = useState(0);
    const [search, setSearch] = useState('');
    const [searchText, setSearchText] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [selfData, setSelfData] = useState(null);
    const [data, setData] = useState([]);

    const getCommissionData = async () => {
        try {
            setIsLoading(true);
            let params = {
                PageNumber: page,
                PageSize: pageSize,
                SearchText: searchText
            }
            const { data } = await GetCommissionAssignTreeApi(id, new URLSearchParams(params).toString());
            if (data?.success) {
                setData(data?.data?.flatMap((user) =>
                    user.accountsList.map((account, index) => ({
                        ...user,
                        name: `${user?.firstName} ${user?.lastName}`,
                        ...account,
                        commission: '$ ' + account?.commission,
                        rowSpan: index === 0 ? user.accountsList.length : 0,
                    })))
                )
                if (selfData == null) {
                    setSelfData(data?.self);
                }
                setTotalRecord(data?.totalItems);
            }
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        getCommissionData();
    }, [page, pageSize, searchText]);

    return (
        <>
            <div className='border-[2px] border-light rounded-xl pt-4 w-full'>
                {
                    (selfData == null && isLoading) ? (
                        <div className="flex justify-center flex-col my-6">
                            <Spin size="large" />
                            <p className="primary text-center mt-2">Loading...</p>
                        </div>
                    ) : (
                        <div className='p-4 mb-3 grid sm:grid-cols-3 gap-5 lg:divide-x'>
                            {
                                selfData?.accountsList?.map((item, index) => (
                                    <div key={index}>
                                        <div className='flex justify-center'>
                                            <p className='text-base font-normal text-center pb-2'>{item?.planName}</p>
                                            
                                        </div>
                                        <p className='text-base font-normal text-center pb-2'>{item?.groupName}</p>
                                        <p className='text-xl primary font-semibold text-center mt-2'>$ {item?.commission}</p>
                                    </div>
                                ))
                            }
                        </div>
                    )
                }
            </div>
            <div className='border-2 border-light rounded-xl pt-4 lg:pt-6 w-full mt-5'>
                <div className="px-4 lg:px-6 flex justify-between items-center">
                    <p className="text-[#2F2B3D] text-xl font-semibold">IB Details</p>
                    <Input
                        size="large"
                        style={{ width: 220 }}
                        placeholder="Search"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        onPressEnter={() => setSearchText(search)}
                        prefix={
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-5 h-5">
                                <path fill-rule="evenodd" d="M10.5 3.75a6.75 6.75 0 1 0 0 13.5 6.75 6.75 0 0 0 0-13.5ZM2.25 10.5a8.25 8.25 0 1 1 14.59 5.28l4.69 4.69a.75.75 0 1 1-1.06 1.06l-4.69-4.69A8.25 8.25 0 0 1 2.25 10.5Z" clip-rule="evenodd" />
                            </svg>
                        }
                        suffix={search &&
                            <svg xmlns="http://www.w3.org/2000/svg" onClick={() => { setSearchText(''); setSearch('') }} viewBox="0 0 24 24" fill="#bebebe" className="size-5 cursor-pointer duration-300 hover:fill-[#969595]">
                                <path fill-rule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm-1.72 6.97a.75.75 0 1 0-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 1 0 1.06 1.06L12 13.06l1.72 1.72a.75.75 0 1 0 1.06-1.06L13.06 12l1.72-1.72a.75.75 0 1 0-1.06-1.06L12 10.94l-1.72-1.72Z" clip-rule="evenodd" />
                            </svg>
                        }
                    />
                </div>
                <div className='my-6'>
                    <TableWithSkeleton columns={columns} data={data} loading={isLoading} rowKey={(record) => `${record.userId}-${record.planName}`} />
                    <MyPagination total={totalRecord} page={page} setPage={setPage} pageSize={pageSize} setPageSize={setPageSize} />
                </div>
            </div>
        </>
    )
}