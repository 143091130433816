import React, { useState, useEffect } from "react";
import columns from '../../columns/EmailTemplate.js';
import { Input } from "antd";
import Pagination from "../../components/global/pagination/Pagination.jsx";
import TableWithSkeleton from "../../components/global/table/TableWithSkeleton.jsx";
import ModalComponent from '../../components/global/modal/ModalComponent';
import { GetEmailTemplatesApi,DeleteEmail } from "../../api/request/emailTemplate.js";
import moment from "moment/moment.js";
import Eye from "../../assets/Eye.jsx";
import Edit from "../../assets/Edit.jsx";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import ConfirmationModal from "../../components/global/modal/ConfirmationModal.jsx";


export default function EmailTemplate() {

    const navigate = useNavigate();
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalRecord, setTotalRecord] = useState(0);
    const [search, setSearch] = useState("");
    const [searchText, setSearchText] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState([]);
    const [selectedEmailTemplate, setSelectedEmailTemplate] = useState(null);
    const [isOpenEmailTemplateModal, setIsOpenEmailTemplateModal] = useState(null);
    const [emailId, setEmailId] = useState(null);
    const [isDelete, setIsDelete] = useState(false);
    const [isDeleteLoading, setIsDeleteLoading] = useState(false);

    const getEmailTemplates = async () => {
        try {
            setIsLoading(true);
            let params = {
                PageNumber: page,
                PageSize: pageSize,
                FilterText: '',
                SearchText: searchText
            };
            const { data } = await GetEmailTemplatesApi(new URLSearchParams(params).toString());
            if (data?.success) {
                setData(
                    data?.data?.map((item) => {
                        return {
                            ...item,
                            templateDetailsCreatedDate: (item?.templateDetailsCreatedDate ? moment(item?.templateDetailsCreatedDate).format("DD-MM-YYYY, hh:mm A") : null),
                            actions: (<div className="flex justify-center gap-4">
                                <button type="button" onClick={() => navigate(`/email_template_view/${item?.emailTemplateId}`)}><Eye /></button>
                                {/* <button type="button" onClick={() => { setSelectedEmailTemplate(item); setIsOpenEmailTemplateModal(true) }}><Eye /></button> */}
                                <button type="button" onClick={() => navigate(`/edit_email_template/${item?.emailTemplateId}`)}><Edit /></button>
                                {/* <button onClick={e => { setEmailId(item?.emailTemplateId); setIsDelete(true) }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#D91819" className="w-6 h-6 cursor-pointer hover:stroke-[#aa0001] duration-500">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                                    </svg>
                                </button> */}
                            </div>),
                        };
                    })
                );
                setTotalRecord(data?.totalRecords);
            }
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        getEmailTemplates();
    }, [page, pageSize, searchText])


    const handleDeleteEmail = async () => {
        try {
          setIsDeleteLoading(true);
          const { data } = await DeleteEmail(emailId);
          if (data?.success) {
            toast.error(data?.message);
            getEmailTemplates();
            setIsDeleteLoading(false);
            setIsDelete(false);
          }
          else {
            toast.message(data?.message);
            setIsDeleteLoading(false);
            setIsDelete(false);
            getEmailTemplates();
          }
        }
        catch (error) {
          toast.error(error?.response?.data?.error?.message);
          setIsDeleteLoading(false);
          setIsDelete(false);
        }
      }


    return (
        <div>
            <div className="flex justify-end flex-wrap gap-5">
                <div className="flex items-center gap-4">
                    <Input size="large" style={{ width: 220 }} placeholder="Search" value={search} onChange={(e) => setSearch(e.target.value)} onPressEnter={() => setSearchText(search)}
                        prefix={
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-5 h-5">
                                <path fill-rule="evenodd" d="M10.5 3.75a6.75 6.75 0 1 0 0 13.5 6.75 6.75 0 0 0 0-13.5ZM2.25 10.5a8.25 8.25 0 1 1 14.59 5.28l4.69 4.69a.75.75 0 1 1-1.06 1.06l-4.69-4.69A8.25 8.25 0 0 1 2.25 10.5Z" clip-rule="evenodd" />
                            </svg>
                        }
                        suffix={
                            search && (
                                <svg xmlns="http://www.w3.org/2000/svg" onClick={() => { setSearchText(""); setSearch(""); }} viewBox="0 0 24 24" fill="#bebebe" className="size-5 cursor-pointer duration-300 hover:fill-[#969595]">
                                    <path fill-rule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm-1.72 6.97a.75.75 0 1 0-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 1 0 1.06 1.06L12 13.06l1.72 1.72a.75.75 0 1 0 1.06-1.06L13.06 12l1.72-1.72a.75.75 0 1 0-1.06-1.06L12 10.94l-1.72-1.72Z" clip-rule="evenodd" />
                                </svg>
                            )
                        }
                    />
                    <button className="flex items-center bg-primary text-sm text-white font-semibold px-6 py-2 rounded-lg themeHover  duration-500" onClick={() => navigate('/add_email_template')}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-4 h-4 mr-2">
                            <path fill-rule="evenodd" d="M12 3.75a.75.75 0 0 1 .75.75v6.75h6.75a.75.75 0 0 1 0 1.5h-6.75v6.75a.75.75 0 0 1-1.5 0v-6.75H4.5a.75.75 0 0 1 0-1.5h6.75V4.5a.75.75 0 0 1 .75-.75Z" clip-rule="evenodd" />
                        </svg>
                        Add Email
                    </button>

                </div>
            </div>

            <div className="border-2 border-[var(--theme-light)] rounded-xl pt-4 lg:pt-6 w-full mt-5">
                <div className="px-4 lg:px-6">
                    <p className="text-[#2F2B3D] text-xl font-semibold">Email Template</p>
                </div>
                <div className="my-6">
                    <TableWithSkeleton columns={columns} data={data} loading={isLoading} />
                    <Pagination total={totalRecord} page={page} setPage={setPage} pageSize={pageSize} setPageSize={setPageSize} />
                </div>
            </div>

            {/* --- View email template modal --- */}
            <ModalComponent isOpen={isOpenEmailTemplateModal} setIsOpen={setIsOpenEmailTemplateModal} title={`Template Preview: ${selectedEmailTemplate?.templateName}`} width={900}>
                <div dangerouslySetInnerHTML={{ __html: selectedEmailTemplate?.body }}></div>
            </ModalComponent>

              {/* ---- Delete email modal ---- */}
        <ConfirmationModal
          isOpen={isDelete}
          setIsOpen={setIsDelete}
          message='Are you sure you want to remove this email template ?'
          onConfirm={handleDeleteEmail}
          isLoading={isDeleteLoading}
          loadingLabel='Deleting Email Template...'
        />
        </div>
    )
}