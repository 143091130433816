import React, { useState, useEffect } from "react";
import { Input } from "antd";
import TableWithSkeleton from "../../components/global/table/TableWithSkeleton.jsx";
import Pagination from "../../components/global/pagination/Pagination.jsx";
import { GetAllCommissionApi } from "../../api/request/settings/configuration.js";
import columns from "../../columns/Commissiondata.js";
import moment from "moment/moment.js";
import Segmented from "../../components/segmented/Segmented.jsx";
import Eye from "../../assets/Eye.jsx";
import { useSearchParams } from "react-router-dom";
import ModalComponent from "../../components/global/modal/ModalComponent.jsx";
import { useLocation } from 'react-router-dom';
import ExportHandler from "../../components/global/export/ExportHandler.jsx";


export default function Commissiondata() {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecord, setTotalRecord] = useState(0);
  const [search, setSearch] = useState("");
  const [searchText, setSearchText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [selectedcommission, setSelectedcommission] = useState(null);
  const [isOpenDetailsModal, setIsOpenDetailsModal] = useState(false);
  const [valuenew, setValuenew] = useState("Calculated");
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const [filterValue, setFilterValue] = useState('');

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const filter = searchParams.get('filter');
    if (filter) {
      setFilterValue(decodeURIComponent(filter));
    }
  }, [location.search]);


  const getAllCommission = async () => {
    try {
      setIsLoading(true);
      let params = {
        PageNumber: page,
        PageSize: pageSize,
        SearchText: searchText,
        FilterText: filterValue
      };
      const { data } = await GetAllCommissionApi(new URLSearchParams(params).toString());
      if (data?.success) {
        setData(
          data?.data?.map((item) => {
            return {
              ...item,
              name: `${item?.firstName} ${item?.lastName}`,
              closeTime: `${filterValue === "Calculated"?(item?.closeTime ? moment(item?.closeTime).utc().format("DD-MM-YYYY, hh:mm A") : null): item?.tradeDuration}`,
              actions: (<div className="flex items-center gap-2">
                <button type="button" onClick={() => { setSelectedcommission(item); setIsOpenDetailsModal(true) }}><Eye /></button>
              </div>
              ),
            };
          })
        );
        setTotalRecord(data?.totalRecords);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    setSearchParams(`filter=${valuenew?.toString()}`);
  }, [valuenew]);

  useEffect(() => {
    if (searchParams?.get("filter")?.trim()) {
      setValuenew(searchParams?.get("filter")?.trim());
    }
  }, []);

  useEffect(() => {
    if (filterValue) {
      getAllCommission();
    }
  }, [page, pageSize, searchText, filterValue]);

  const exportHandler = async () => {
    try {
      let params = {
        ApplyPagination: false,
        SearchText: searchText.toLowerCase().replace(/\s/g, ''),
        FilterText: filterValue
      };
      const { data } = await GetAllCommissionApi(new URLSearchParams(params).toString());
      if (data?.success) {
        return { data: data?.data, removableColumns: ['userId', 'requestId'], fileName: 'Threshold Reports' };
      } else {
        return { data: [], removableColumns: [] };
      }
    } catch (error) {
      return { data: [], removableColumns: [] };
    }
  };


  return (
    <div className="max-w-full">
      <div className="flex justify-between flex-wrap gap-5">
        <Segmented
          options={["Calculated", "Excluded"]}
          value={valuenew}
          setValue={(value) => {
            setValuenew(value);
            setPage(1); 
        }} 
        />
        <div className="flex items-center flex-wrap gap-4">
          <div >

            <Input
              size="large"
              style={{ width: 220 }}
              placeholder="Search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              onPressEnter={() => setSearchText(search)}
              prefix={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  class="w-5 h-5"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10.5 3.75a6.75 6.75 0 1 0 0 13.5 6.75 6.75 0 0 0 0-13.5ZM2.25 10.5a8.25 8.25 0 1 1 14.59 5.28l4.69 4.69a.75.75 0 1 1-1.06 1.06l-4.69-4.69A8.25 8.25 0 0 1 2.25 10.5Z"
                    clip-rule="evenodd"
                  />
                </svg>
              }
              suffix={
                search && (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    onClick={() => {
                      setSearchText("");
                      setSearch("");
                    }}
                    viewBox="0 0 24 24"
                    fill="#bebebe"
                    className="size-5 cursor-pointer duration-300 hover:fill-[#969595]"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm-1.72 6.97a.75.75 0 1 0-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 1 0 1.06 1.06L12 13.06l1.72 1.72a.75.75 0 1 0 1.06-1.06L13.06 12l1.72-1.72a.75.75 0 1 0-1.06-1.06L12 10.94l-1.72-1.72Z"
                      clip-rule="evenodd"
                    />
                  </svg>
                )
              }
            />

          </div>
          <div>
            <ExportHandler exportHandler={exportHandler} />
          </div>
        </div>

        <div className="border-[2px] border-[var(--theme-light)] rounded-xl pt-4 lg:pt-6 w-full mt-5">
          <div className="px-4 lg:px-6">
            <p className="text-[#2F2B3D] text-xl font-semibold">Commission Data</p>
          </div>
          <div className="my-6">
            <TableWithSkeleton columns={columns} data={data} loading={isLoading} />
            <Pagination total={totalRecord} pageSize={pageSize} setPageSize={setPageSize} page={page} setPage={setPage} />
          </div>
        </div>
      </div>

      <ModalComponent
        isOpen={isOpenDetailsModal}
        setIsOpen={setIsOpenDetailsModal}
        title='Commission Information'
        width={1300}
      >
        <div className='p-5'>
          <div className='grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 gap-10 '>
            <div>
              <p className='text-base font-normal'>First Name</p>
              <p className='text-xl primary font-semibold mt-2'>{selectedcommission?.firstName || '---'}</p>
            </div>
            <div>
              <p className='text-base font-normal'>Last Name</p>
              <p className='text-xl primary font-semibold mt-2'>{selectedcommission?.lastName || '---'}</p>
            </div>
            <div>
              <p className='text-base font-normal'>Email</p>
              <p className='text-xl primary font-semibold mt-2'>{selectedcommission?.email || '---'}</p>
            </div>
            <div>
              <p className='text-base font-normal'>Mobile No.</p>
              <p className='text-xl primary font-semibold mt-2'>{selectedcommission?.mobileNo || '---'}</p>
            </div>
            <div>
              <p className='text-base font-normal'>Account No.</p>
              <p className='text-xl primary font-semibold mt-2'>{selectedcommission?.loginId || '---'}</p>
            </div>
            <div>
              <p className='text-base font-normal'>Deal Id</p>
              <p className='text-xl primary font-semibold mt-2'>{selectedcommission?.dealId || '---'}</p>
            </div>
            <div>
              <p className='text-base font-normal'>Position Id</p>
              <p className='text-xl primary font-semibold mt-2'>{selectedcommission?.positionId || '---'}</p>
            </div>
            <div>
              <p className='text-base font-normal'>Open Price</p>
              <p className='text-xl primary font-semibold mt-2'>$ {selectedcommission?.openPrice || '---'}</p>
            </div>
            <div>
              <p className='text-base font-normal'>Close Price</p>
              <p className='text-xl primary font-semibold mt-2'>$ {selectedcommission?.closePrice || '---'}</p>
            </div>
            <div>
              <p className='text-base font-normal'>Open Time</p>
              <p className='text-xl primary font-semibold mt-2'>{selectedcommission?.openTime ? moment(selectedcommission?.openTime).utc().format('DD-MM-YYYY, hh:mm A') : '---'}</p>
            </div>
            <div>
              <p className='text-base font-normal'>Close Time</p>
              <p className='text-xl primary font-semibold mt-2'>{selectedcommission?.closeTime ? moment(selectedcommission?.closeTime).utc().format('DD-MM-YYYY, hh:mm A') : '---'}</p>
            </div>
            <div>
              <p className='text-base font-normal'>Trade Duration</p>
              <p className='text-xl primary font-semibold mt-2'>{selectedcommission?.tradeDuration || '---'}</p>
            </div>
            <div>
              <p className='text-base font-normal'>Profit</p>
              <p className='text-xl primary font-semibold mt-2'>{selectedcommission?.profit || '---'}</p>
            </div>
            {/* <div>
              <p className='text-base font-normal'>Thres Hold Time</p>
              <p className='text-xl primary font-semibold mt-2'>{selectedcommission?.thresHoldTime || '---'}</p>
            </div> */}
            <div>
              <p className='text-base font-normal'>Symbol</p>
              <p className='text-xl primary font-semibold mt-2'>{selectedcommission?.symbol || '---'}</p>
            </div>
            <div>
              <p className='text-base font-normal'>Commission</p>
              <p className='text-xl primary font-semibold mt-2'>{selectedcommission?.isThresHold || '---'}</p>
            </div>
            <div>
              <p className='text-base font-normal'>Group Name</p>
              <p className='text-xl primary font-semibold mt-2'>{selectedcommission?.groupName || '---'}</p>
            </div>
          </div>
        </div>

      </ModalComponent>

    </div>
  )
}
