import React, { useState, useEffect } from "react";
import Pagination from "../../components/global/pagination/Pagination.jsx";
import columns from "../../columns/MT5Account/MT5AcceptedAccounts.js";
import TableWithSkeleton from "../../components/global/table/TableWithSkeleton.jsx";
import { GetAllAccountApi, GetAllMT5ByIdApi } from "../../api/request/account.js";
import ModalComponent from "../../components/global/modal/ModalComponent.jsx";
import moment from "moment";
import Eye from "../../assets/Eye.jsx";

export default function Accounts({ dates }) {

  const [isLoading, setIsLoading] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState([]);
  const [totalRecord, setTotalRecord] = useState(0);
  const [data, setData] = useState([]);
  const [selectedMT5Id, setSelectedMT5Id] = useState('');
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [mt5Details, setMT5Details] = useState({});
  const [sorter, setSorter] = useState({ SortBy: 'actionDate', SortOrder: '' });

  const getAllMt5 = async () => {
    try {
      let params = {
        PageNumber: page,
        PageSize: pageSize,
        SearchText: dates,
        FilterText: "Approved",
        ...sorter
      }
      setIsLoading(true);
      const { data } = await GetAllAccountApi(new URLSearchParams(params).toString());
      if (data?.success) {
        setData(
          data?.data?.map((item) => {
            return {
              ...item,
              balance:`$ ${item?.balance}`,
              actionDate: (item?.actionDate ? moment(item?.actionDate).format("DD-MM-YYYY, hh:mm A") : null),

              actions: (
                <div className="flex">
                  <button type="button" onClick={() => { setSelectedMT5Id(item?.login); setIsOpenModal(true) }}><Eye /></button>
                </div>
              ),
            };
          })
        );
        setTotalRecord(data?.totalRecords);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  }
  const handleTableChange = (pagination, filters, sorter) => {
    if (sorter?.order) {
        setSorter({ SortBy: sorter?.field, SortOrder: sorter?.order });
    } else {
        setSorter({ SortBy: 'actionDate', SortOrder: '' });
    }
};

  useEffect(() => {
    getAllMt5();
  }, [page, pageSize, dates, filter, sorter]);

  const getMT5ById = async () => {
    try {
      const { data } = await GetAllMT5ByIdApi(selectedMT5Id);
      setMT5Details(data?.data);
    } catch (error) { }
  }

  useEffect(() => {
    if (selectedMT5Id) {
      getMT5ById();
    }
  }, [selectedMT5Id]);

  return (
    <div>

      <div className="border-[2px] border-[var(--theme-light)] rounded-xl pt-4 lg:pt-6 w-full mt-5">
        <div className="px-4 lg:px-6">
          <p className="text-[#2F2B3D] text-xl font-semibold">
            MT5 Accept
          </p>
        </div>
        <div className="my-6">
          <TableWithSkeleton columns={columns} data={data} loading={isLoading}  onChange={handleTableChange}/>
          <Pagination total={totalRecord} page={page} setPage={setPage} pageSize={pageSize} setPageSize={setPageSize} />
        </div>
      </div>


      {/* ---- MT5 Account Information ---- */}
      <ModalComponent isOpen={isOpenModal} setIsOpen={setIsOpenModal} title='MT5 Account Information' width={1300}>
        <div className='p-5'>
          <div className='grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 gap-10 '>
            <div>
              <p className='text-base font-normal '>First Name</p>
              <p className='text-xl primary font-semibold  mt-2'>{mt5Details?.firstName || "--"}</p>
            </div>
            <div>
              <p className='text-base font-normal '>Last Name</p>
              <p className='text-xl primary font-semibold  mt-2'>{mt5Details?.lastName || "--"}</p>
            </div>
            <div>
              <p className='text-base font-normal '>Account No.</p>
              <p className='text-xl primary font-semibold  mt-2'>{mt5Details?.login || "--"}</p>
            </div>
            <div>
              <p className='text-base font-normal '>Country</p>
              <p className='text-xl primary font-semibold  mt-2'>{mt5Details?.country || "--"}</p>
            </div>
            <div>
              <p className='text-base font-normal '>Credit</p>
              <p className='text-xl primary font-semibold  mt-2'> {mt5Details?.credit || "0"}</p>
            </div>
            <div>
              <p className='text-base font-normal '>Balance</p>
              <p className='text-xl primary font-semibold  mt-2'>$ {mt5Details?.balance || "0"}</p>
            </div>
            <div>
              <p className='text-base font-normal '>Leverage</p>
              <p className='text-xl primary font-semibold  mt-2'>{mt5Details?.leverage || "0"}</p>
            </div>
            <div>
              <p className='text-base font-normal '>Margin</p>
              <p className='text-xl primary font-semibold  mt-2'>{mt5Details?.margin || '0'}</p>
            </div>
            <div>
              <p className='text-base font-normal '>Margin Free</p>
              <p className='text-xl primary font-semibold  mt-2'>{mt5Details?.marginFree || '0'}</p>
            </div>
            <div>
              <p className='text-base font-normal '>Group</p>
              <p className='text-xl primary font-semibold  mt-2'>{mt5Details?.group || '--'}</p>
            </div>
            <div>
              <p className='text-base font-normal '>Profit</p>
              <p className='text-xl primary font-semibold  mt-2'>{mt5Details?.profit || '0'}</p>
            </div>
            <div>
              <p className='text-base font-normal '>Commission</p>
              <p className='text-xl primary font-semibold  mt-2'>{mt5Details?.Commission || '0'}</p>
            </div>
            <div>
              <p className='text-base font-normal '>Equity</p>
              <p className='text-xl primary font-semibold  mt-2'>{mt5Details?.equity || '0'}</p>
            </div>
            <div>
              <p className='text-base font-normal '>Balance Prev Day</p>
              <p className='text-xl primary font-semibold  mt-2'>{mt5Details?.balancePrecDay || '0'}</p>
            </div>
            <div>
              <p className='text-base font-normal '>Equity Prev Day</p>
              <p className='text-xl primary font-semibold  mt-2'>{mt5Details?.equityPrecDay || '0'}</p>
            </div>
            {mt5Details?.status && <div>
              <p className='text-base font-normal '>Status</p>
              <p className='text-xl primary font-semibold  mt-2'>{mt5Details?.status || '--'}</p>
            </div>  }         
            <div>
              <p className='text-base font-normal '>Trading Password</p>
              <p className='text-xl primary font-semibold  mt-2'>{mt5Details?.tradingPassword || '---'}</p>
            </div>           
            <div>
              <p className='text-base font-normal '>Investor Password</p>
              <p className='text-xl primary font-semibold  mt-2'>{mt5Details?.investorPassword || '---'}</p>
            </div>           
          </div>
        </div>       
      </ModalComponent>
    </div>
  )
}