import React, { useState, useEffect } from "react";
import { Input, Form, Select,Switch} from "antd";
import columns from "../../../columns/Rules.js";
import Pagination from "../../../components/global/pagination/Pagination.jsx";
import ConfirmationModal from "../../../components/global/modal/ConfirmationModal.jsx";
import TableWithSkeleton from "../../../components/global/table/TableWithSkeleton.jsx";
import moment from "moment";
import { GetAllRulesApi,UpdateUserStatusApi,UpdateRulesApi,GetrulesByIdApi} from "../../../api/request/settings/rules.js";
import toast from "react-hot-toast";
import Edit from "../../../assets/Edit.jsx";
import ModalComponent from "../../../components/global/modal/ModalComponent.jsx";
import LoadableButton from "../../../components/buttons/LoadableButton.jsx";


export default function Rules({allfilter}) {
  const [form] = Form.useForm();
  const [pageSize, setPageSize] = useState(10);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [selectedRulesId, setSelectedRulesId] = useState("");
  const [totalRecord, setTotalRecord] = useState(0);
  const [isStatusConfirmOpen, setIsStatusConfirmOpen] = useState(false);
  const [isStatusConfirmLoading, setIsStatusConfirmLoading] = useState(false);
  const [rulesall,setrulesall] = useState()
  const [isEditTraderLoading, setIsEditTraderLoading] = useState(false);
  const [isOpenEditModal, setIsOpenEditModal] = useState(false);
  const [trader, setTrader] = useState({});
  const [initialValues, setInitialValues] = useState({ demoAccount: "", liveAccount: "", description: "", ruleName: "",isDisable: ""});
  const [data, setData] = useState([]);

  const getAllRules = async () => {
    try {
      let params = {
        PageNumber: page,
        PageSize: pageSize,
        filterByCategory2:
        allfilter === "All Rules"
            ? " "
            : allfilter === "Internal Transfer"
              ? "InternalTransfer"
              : allfilter === "IB Wallet Withdrawal"
              ? "IBWalletWithdrawal"
                : allfilter,
      }
      setIsLoading(true);
      const { data } = await GetAllRulesApi(new URLSearchParams(params)?.toString());
      if (data?.success) {
        setData(
          data?.data?.map((item) => {
            return {
              ...item,
              name: `${item?.firstName} ${item?.lastName}`,
              condition1:`$ ${item?.condition1}`,
              condition2:`$ ${item?.condition2}`,
              lastModifiedDate: (item?.lastModifiedDate ? moment(item?.lastModifiedDate).format('DD-MM-YYYY, hh:mm A') : '--'),
              actions: (<button type="button" onClick={() => { setSelectedRulesId(item?.ruleId); setIsOpenEditModal(true); }}><Edit /></button>),
              isDefault: (<Switch onChange={() => statusHandler({ isDefault: item?.isDefault, ruleId: item?.ruleId, ruleTypeId: item?.ruleTypeId })} checkedChildren="Active" unCheckedChildren="Inactive" checked={item?.isDefault} />),
            };
          })
        );
        setTotalRecord(data?.totalRecords);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAllRules();
  }, [page, pageSize, allfilter]);

  const statusHandler = async (params) => {
    setIsStatusConfirmOpen(true);
    setrulesall(params)
  }

  const statusChangeConfirm = async () => {
    try {
      setIsStatusConfirmLoading(true);

      const { data } = await UpdateUserStatusApi(rulesall);
      if (data?.success) {
        getAllRules();
        toast.success(data?.message);
      }

      setIsStatusConfirmLoading(false);
      setIsStatusConfirmOpen(false);
    } catch (error) {
      setIsStatusConfirmLoading(false);
    }
  }

  const handleFieldsChange = (_, allFields) => {
    const isChanged = allFields.some(field => field.touched);
  };

  const getRulesById = async () => {
    try {
      setIsLoading(true);
      const { data } = await GetrulesByIdApi(selectedRulesId);
      if (data?.success) {
        const values = {
          condition1: data?.data?.condition1,
          description: data?.data?.description,
          condition2: data?.data?.condition2,
          ruleName: data?.data?.ruleName,
          IsDisable: data?.data?.isDisable,
        };
        setInitialValues(values);
        form.setFieldsValue(values); 
        setTrader(data?.data);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if(selectedRulesId){
        getRulesById();
    }
   
  }, [selectedRulesId]);

  const handleEditSubmit = async (value) => {
    try {
     
      let apiParams = {
        ...value,
        ruleId: trader?.ruleId,
        isAuto: true,
        ruleType: trader?.ruleType,
        IsDisable: trader.isDisable === 'true',
      };
      setIsEditTraderLoading(true);
      const { data } = await UpdateRulesApi(apiParams);
      if (data?.success) {
        toast.success(data?.message);
        setIsEditTraderLoading(false);
        setIsOpenEditModal(false);
        getAllRules();
      }
    } catch (error) {
      toast.error(error?.response?.data?.error?.message);
      setIsEditTraderLoading(false);
    }
  };

  return (
    <div>
      <div className="border-[2px] border-[var(--theme-light)] rounded-xl pt-4 lg:pt-6 w-full mt-5">
        <div className="px-4 lg:px-6">
          <p className="text-[#2F2B3D] text-xl font-semibold">Rules</p>
        </div>
        <div className="mt-6">
          <TableWithSkeleton columns={columns} data={data} loading={isLoading} />
          <Pagination total={totalRecord} pageSize={pageSize} setPageSize={setPageSize} />
        </div>

        <ConfirmationModal
        isOpen={isStatusConfirmOpen}
        setIsOpen={setIsStatusConfirmOpen}
        message='Are you sure to change the set Default rule?'
        onConfirm={statusChangeConfirm}
        isLoading={isStatusConfirmLoading}
        loadingLabel='Changing status...'
      />

      </div>
       {/* Update user modal */}
       <ModalComponent isOpen={isOpenEditModal} setIsOpen={setIsOpenEditModal} title="Rules Update" width={1200}>
        <Form className="w-full" autoComplete="off" form={form} initialValues={initialValues} onFinish={handleEditSubmit} onFieldsChange={handleFieldsChange}>
          <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-5">
            <div className="input-white">
              <label className="text-base text-[#2F2B3DCC] font-medium">Minimum Transfer</label>
              <Form.Item name="condition1" rules={[{ required: true, message: "Please enter minimum transfer." }]}>
                <Input placeholder="Minimum Transfer" className="mt-1 w-full py-2" />
              </Form.Item>
            </div>
            <div className="input-white">
              <label className="text-base text-[#2F2B3DCC] font-medium">Maximum Transfer</label>
              <Form.Item name="condition2" rules={[{ required: true, message: "Please enter maximum transfer." }]}>
                <Input placeholder="Maximum Transfer" className="mt-1 w-full py-2" />
              </Form.Item>
            </div>
            <div className="input-white">
              <label className="text-base text-[#2F2B3DCC] font-medium">Rule Name</label>
              <Form.Item name="ruleName" rules={[{ required: true,  message: "Please enter rule name.", },]}>
                <Input  placeholder="Rule Name" className="mt-1 w-full py-2" />
              </Form.Item>
            </div>
            <div className="input-white">
              <label className="text-base text-[#2F2B3DCC] font-medium">Description</label>
              <Form.Item name="description" rules={[{ required: true, message: "Please enter description.", }]}>
                <Input  placeholder="Description" className="mt-1 w-full py-2" />
              </Form.Item>
            </div>
            <div className="ant-select-selector-white">
              <label className="text-base text-[#2F2B3DCC] font-medium">Disabled</label>
              <Form.Item name="isDisable" rules={[{ required: true, message: "Please select a role." }]}>
                <Select placeholder="Select a role" className="w-full mt-1" options={[{ value: true, label: "Yes" }, { value: false , label: "No" },]} />
              </Form.Item>
            </div>
            
          </div>
          <div className="flex justify-end mt-8">
            <LoadableButton
              className="bg-primary text-sm text-white font-semibold rounded-lg px-12 py-2 uppercase themeHover  duration-500"
              type="Submit"
              lable="Update"
              isLoading={isEditTraderLoading}
              loadingLable="Updating rules..."
            />
          </div>
        </Form>
      </ModalComponent>
    </div>
  );
}
